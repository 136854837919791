import React from 'react';
import AppAppBar from '../onepirate/modules/views/AppAppBar';
import AppFooter from '../onepirate/modules/views/AppFooter';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    container: {
        // Safari doesn't like height combined with any flex related settings
        // even it's parent is not flex layout. Hence minHeight used here
        // which works well as well
        // Chrome and Firefox don't like height and flexGrow settings together
        // when it's parent is flex layout, causing overridden when scrolling
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    mainVerticalCenter: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    verticalCenterBodyWrap: {
        width: '100%',
    },
    main: {
        flexGrow: 1
    },
    pseudoFooter: {
        height:'70px'  // same as the App Bar height
    }
});

function Page(props) {

    const { classes, history, isVerticalCenter, children, skipMain } = props;
    const { showMenu, showBackButton, showSignInLink, showFooter } = props;

    return (
        <div className={classes.container}>
            <AppAppBar history={history} showMenu={showMenu} showBackButton={showBackButton} showSignInLink={showSignInLink}/>
            {
                isVerticalCenter &&
                <main className={classes.mainVerticalCenter}>
                    <div className={classes.verticalCenterBodyWrap}>
                        {children}
                    </div>
                </main>
            }
            {
                !isVerticalCenter &&
                skipMain &&
                children
            }
            {
                !isVerticalCenter &&
                !skipMain &&
                <main className={classes.main}> {/* provide 100% height and width to children */}
                    {children}
                </main>
            }
            {
                showFooter &&
                <AppFooter history={history}/>                
            }
            {
                !showFooter &&
                isVerticalCenter &&
                <div className={classes.pseudoFooter}/>                
            }
         </div>
    );
}

export default withStyles(styles)(Page);
