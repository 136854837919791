import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Lightbox from 'react-image-lightbox';

import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import TextButton from '../onepirate/modules/components/TextButton';

import { handleUploadImage } from '../functions/ImageFunctions';

const styles = theme => ({

  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  photoInput: {
    display: 'none'
  }
});

class PhotoManager extends React.Component {

  constructor(props) {
    super(props);
 
    this.state = {
      isOpen: false,
      removePhotoDialogOpen: false
    };
    
  } 

  render() {

    const { classes } = this.props;
    const { deletePrompt } = this.props;
    const { photo } = this.props;
    const { onClose } = this.props;

    return (

      <>
        <Lightbox
          mainSrc={photo}
          onCloseRequest={onClose}
          enableZoom={false}
          toolbarButtons={[
            <label htmlFor="icon-button-upload-photo">
              <IconButton component="span">
                <PhotoCamera className={classes.icon} />
              </IconButton>
              <input
              accept="image/*"
              className={classes.photoInput}
              id="icon-button-upload-photo"
              onChange={ (event) => handleUploadImage(event, this.props.uploadedImageMaxWith, this.props.onPhotoReplaced)}
              type="file"
              />
            </label>
            ,
            <IconButton onClick={this.handleRemovePhotoDialogClickOpen}>
              <DeleteOutlinedIcon className={classes.icon} />
            </IconButton>
          ]}
        />

      
      <Dialog
        open={this.state.removePhotoDialogOpen}
        onClose={this.handleRemovePhotoDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {deletePrompt}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton onClick={this.handleRemovePhotoDialogClose} >
            No
        </TextButton>
        <TextButton onClick={() => this.handleRemovePhoto()} >
            Yes
        </TextButton>

      </DialogActions>
    </Dialog>
    </>
    )
  };

  handleRemovePhotoDialogClickOpen = () => {
    this.setState({ removePhotoDialogOpen: true });
  };

  handleRemovePhotoDialogClose = () => {
    this.setState({ removePhotoDialogOpen: false });
  };

  handleRemovePhoto = (index) => {

    this.setState({ removePhotoDialogOpen: false });

    // call below only when file is removed
    this.props.onPhotoDeleted();
  };
}

export default withStyles(styles)(withMobileDialog()(PhotoManager));
