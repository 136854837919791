import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import withMobileDialog from '@material-ui/core/withMobileDialog';

import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fingerprint2 from 'fingerprintjs2';
import Typography from '../onepirate/modules/components/Typography';
import StandardButton from '../onepirate/modules/components/StandardButton';
import isEmail from 'validator/lib/isEmail';
import * as Constants from '../functions/Constants';
import { ConsoleLogger } from '@aws-amplify/core';

var hash = require('object-hash');

const styles = theme => ({

  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  dialogTitle: {
    paddingBottom: '15px',
    textAlign: 'center'
  },
  progress: {
    margin: theme.spacing(2),
  }
});

const MISSING_FIELD_ERROR_MSG = "Required";

const NOT_YET_VERIFIED_ERROR_MSG = "Identity not verified";

class ContactDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      name: props.viewerId,
      email: props.viewerId,
      message: "",

      nameError: "",
      emailError: "",
      messageError: "",
      
      submitInProgress: false,
      viewerId: props.viewerId
    };

    Fingerprint2.get( (components) => {
        this.setState({browserFingerprint : hash(components)})});
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  handleInputOnChange = (event) => {

    const updatedFieldName = event.target.name;
    const updatedValue = event.target.value;
    console.log("updatedFieldName: " + updatedFieldName);
    console.log("updatedValue: " + updatedValue);

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    this.setState(state => ({
      [updatedFieldName]: updatedValue
    }))
  }

  uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

  retrieveBrowserId = () => {
    var browserId = localStorage.getItem("b");
    if (!browserId) {
      browserId = this.uuidv4();
      localStorage.setItem("b", browserId);
    }
    console.log('b', browserId);
    return browserId;
  }

  onSubmit = () => {

    let dataModel = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    };

    console.log("dataModel", dataModel);
    var atLeastOneError = false;
    // Validation
    if (!dataModel.name) {
      this.setState({nameError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({nameError: ''});
    }

    if (!dataModel.email) {
      this.setState({emailError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (!this.state.viewerId && !isEmail(dataModel.email.trim())) {
      this.setState({emailError: "Invalid email address"});
      atLeastOneError = true;      
    } else {
      this.setState({emailError: ''});
    }

    if (!dataModel.message) {
      this.setState({messageError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;
    } else {
      this.setState({messageError: ''});
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({submitInProgress: true});

    console.log("dataModel: " + JSON.stringify(dataModel));

    fetch(
      Constants.HS_MESSAGE_API_URL + "/" + this.props.profileId,
      {
        headers: new Headers(
        {
          "Content-Type": "application/json"
        }),
        method: "POST",
        body: JSON.stringify(dataModel)
      }
    )
    .then(response => {
      console.log("statusCode: " + response.status);
      this.setState({
        // disable all the spinners as we don't know which one triggers this
        submitInProgress: false
      });
      if (response.status === 200) {
        this.props.onClose();
      }
    })
    .catch(err => {
      console.log("Error", err);      
      this.setState({
        submitInProgress: false
      });
    })
  }

  render() {

    const { onClose } = this.props;
    const { classes } = this.props;
    const { fullScreen } = this.props;

    console.log(classes);

    return (
      <div>
        <Dialog
          open={true}
          fullWidth
          fullScreen={fullScreen}
          maxWidth="sm"
          scroll='body'
        >          
          <br/>
          <DialogContent>
            {
              !this.state.viewerId &&
              <>
                <FormControl error={this.state.nameError ? true : false} fullWidth>
                  <FormLabel htmlFor="name" required>Your name</FormLabel>
                  <TextField
                      margin="normal"
                      name="name"                      
                      variant="outlined"
                      autoComplete="name"
                      value={this.state.name}
                      onChange={this.handleInputOnChange}
                      error={this.state.nameError ? true : false}                                                                          
                      inputProps={{
                        maxLength: 100
                      }}
                      onKeyDown={this.onKeyDown}
                  />
                  {
                    this.state.nameError &&
                    <FormHelperText>{this.state.nameError}</FormHelperText>
                  }
                </FormControl>                
                <br/>
                <br/>
              </>
            }
            {
              !this.state.viewerId &&
              <>
                <FormControl error={this.state.emailError ? true : false} fullWidth>
                  <FormLabel htmlFor="email" required>Your Email Address</FormLabel>
                  <TextField
                      margin="normal"
                      name="email"
                      variant="outlined"
                      value={this.state.email}
                      onChange={this.handleInputOnChange}
                      error={this.state.emailError ? true : false}                                                                          
                      inputProps={{
                        maxLength: 100
                      }}
                      onKeyDown={this.onKeyDown}
                  />
                  {
                    this.state.emailError &&
                    <FormHelperText>{this.state.emailError}</FormHelperText>
                  }                                   
                </FormControl>    
                <br/>
                <br/>
              </>
            }
            <FormControl error={this.state.messageError ? true : false} fullWidth>
              <FormLabel htmlFor="email" required>Message</FormLabel>
              <TextField
                  margin="normal"
                  name="message"
                  multiline
                  rows="4"
                  fullWidth
                  variant="outlined"
                  value={this.state.message}
                  onChange={this.handleInputOnChange}
                  inputProps={{
                    maxlength: 500
                  }}
                />
              {
                this.state.messageError &&
                <FormHelperText>{this.state.messageError}</FormHelperText>
              }                                   
            </FormControl>    
            <br/>
            <br/>
          </DialogContent>
          <DialogActions>
            <CircularProgress size={25} className={classes.progress} style={{visibility:this.state.submitInProgress ? 'visible' : 'hidden'}}/>
            <StandardButton color="secondary" onClick={this.onSubmit} >
              Send
            </StandardButton>
            <StandardButton color="secondary" onClick={onClose} >
              Cancel
            </StandardButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  handleFacebookResponse = (response) => {
    console.log('response', response);

    if (response.grantedScopes.includes('email') &&
        response.grantedScopes.includes('public_profile')) {

      this.setState({
        facebookAccessToken: response.accessToken,
        reviewerDisplayName: response.first_name +
         (response.last_name ? ' ' + response.last_name.slice(0,1) + '.' : '')
      });

    }
  }
}

ContactDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withMobileDialog()(ContactDialog));