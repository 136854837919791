import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    // following styles are required to make footer staying at the bottom of the page
    '@global': {
        body: {
            height: '100%'
        },
        html: {
            height: '100%'
        },
        'div#root': {
            height: '100%'
        }
    },
});

export default function GlobalCss() {
  useStyles();
  return null;
}