import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import * as Constants from '../functions/Constants';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Rating from '@material-ui/lab/Rating';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Fingerprint2 from 'fingerprintjs2';
import Typography from '../onepirate/modules/components/Typography';
import StandardButton from '../onepirate/modules/components/StandardButton';
import Link from '@material-ui/core/Link';

var hash = require('object-hash');

const styles = theme => ({

  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  dialogTitle: {
    paddingBottom: '15px',
    textAlign: 'center'
  },
  progress: {
    margin: theme.spacing(2),
  }
});

const MISSING_FIELD_ERROR_MSG = "Required";

const NOT_YET_VERIFIED_ERROR_MSG = "Identity not verified";

class ReviewDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      servicePeriod: "Empty",
      rating: null,
      feedback: "",
      returnLikelihood: null,
      isRegularCustomer: null,

      servicePeriodError: "",
      ratingError: "",
      returnLikelihoodError: "",
      isRegularCustomerError: "",

      submitInProgress: false,
      reviewerDisplayName: null,
      facebookAccessToken: null,
      facebookAccessTokenError: "",
    };

    Fingerprint2.get( (components) => {
        this.setState({browserFingerprint : hash(components)})});
  }

  handleInputOnChange = (event) => {

    const updatedFieldName = event.target.name;
    const updatedValue = event.target.value;
    console.log("updatedFieldName: " + updatedFieldName);
    console.log("updatedValue: " + updatedValue);

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    this.setState(state => ({
      [updatedFieldName]: updatedValue
    }))
  }

  uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

  retrieveBrowserId = () => {
    var browserId = localStorage.getItem("b");
    if (!browserId) {
      browserId = this.uuidv4();
      localStorage.setItem("b", browserId);
    }
    console.log('b', browserId);
    return browserId;
  }

  onSubmitReview = () => {

    let dataModel = {
      servicePeriod: this.state.servicePeriod === 'Empty' ? "" : this.state.servicePeriod,
      rating: parseInt(this.state.rating),
      feedback: this.state.feedback,
      returnLikelihood: this.state.returnLikelihood,
      isRegularCustomer: this.state.isRegularCustomer == null ? null : this.state.isRegularCustomer === 'true',
      profileId: this.props.profileId,
      t: this.state.facebookAccessToken,
      b: this.retrieveBrowserId(),
      bb: this.state.browserFingerprint
    };

    var atLeastOneError = false;
    // Validation
    if (!dataModel.servicePeriod) {
      this.setState({servicePeriodError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({servicePeriodError: ''});
    }

    if (!dataModel.rating) {
      this.setState({ratingError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({ratingError: ''});
    }

    if (!dataModel.returnLikelihood) {
      this.setState({returnLikelihoodError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;
    } else {
      this.setState({returnLikelihoodError: ''});
    }

    if (dataModel.isRegularCustomer == null) {
      this.setState({isRegularCustomerError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({isRegularCustomerError: ''});
    }

    if (dataModel.t == null) {
      this.setState({facebookAccessTokenError: NOT_YET_VERIFIED_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({facebookAccessTokenError: ''});
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({submitInProgress: true});

    console.log("dataModel: " + JSON.stringify(dataModel));
  
    fetch(
      Constants.HS_REVIEW_API_URL,
      {
        headers: new Headers(
        {
          "Content-Type": "application/json"
        }),
        method: "POST",
        body: JSON.stringify(dataModel)
      }
    )
    .then(response => {
      console.log("statusCode: " + response.status);
      this.setState({
        // disable all the spinners as we don't know which one triggers this
        submitInProgress: false
      });
      if (response.status === 200) {
        this.props.onClose();
      }
    })
    .catch(err => {
      console.log("Error", err);      
      this.setState({
        submitInProgress: false
      });
    })
  }

  render() {

    const { onClose } = this.props;
    const { classes } = this.props;
    const { fullScreen } = this.props;

    console.log(classes);

    return (
      <div>
        <Dialog
          open={true}
          fullWidth
          fullScreen={fullScreen}
          maxWidth="sm"
          scroll='body'
        >
          
          <DialogTitle id="form-dialog-title">
            <Typography variant="subtitle1" color="secondary" className={classes.dialogTitle}>
              Tell us your customer experience
            </Typography>
          </DialogTitle>
          <DialogContent>

        <FormControl error={this.state.servicePeriodError ? true : false} className={classes.formControl}>
          <FormLabel htmlFor="servicePeriod" required>When was your experience?</FormLabel>
          <Select
            value={this.state.servicePeriod}          
            onChange={this.handleInputOnChange}
            inputProps={{
              name: 'servicePeriod'
            }}
          >
            <MenuItem value="Empty">Please select</MenuItem>
            <MenuItem value="This Month">This month</MenuItem>
            <MenuItem value="Last Month">Last month</MenuItem>
            <MenuItem value="2 Months Ago">2 months ago</MenuItem>
            <MenuItem value="3 Months Ago">3 months ago</MenuItem>
          </Select>
          {
              this.state.servicePeriodError &&
              <FormHelperText>{this.state.servicePeriodError}</FormHelperText>            
          }          
        </FormControl>

        <br/>
        <br/>
        <br/>
{/*}
            <TextField
              margin="normal"
              id="serviceType"
              label="Type of services"
              placeholder="e.g. Hair Cut"
              fullWidth
            />
*/}

          <FormControl error={this.state.ratingError ? true : false} component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" required>Rating</FormLabel>
            <Rating
              name="rating"
              value={this.state.rating}
              onChange={this.handleInputOnChange}
            />
            {
              this.state.ratingError &&
              <FormHelperText>{this.state.ratingError}</FormHelperText>            
            }            
          </FormControl>

          <br/>
          <br/>

            <TextField
              margin="normal"
              name="feedback"
              label="Feedback"
              multiline
              rows="4"
              fullWidth
              variant="outlined"
              value={this.state.feedback}
              placeholder="What did you like? Any suggestions for improvement?"
              onChange={this.handleInputOnChange}
              inputProps={{
                maxlength: 500
              }}
            />

            <br/>
            <br/>

          <FormControl error={this.state.returnLikelihoodError ? true : false} component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" required>How likely will you get {this.props.firstName} for services again?</FormLabel>
            <RadioGroup
              name="returnLikelihood"
              className={classes.group}
              value={this.state.returnLikelihood}
              onChange={this.handleInputOnChange}
              row
            >
              <FormControlLabel value="Likely" control={<Radio color="default"/>} label="Likely&nbsp;&nbsp;&nbsp;"/>
              <FormControlLabel value="Very Likely" control={<Radio color="default"/>} label="Very Likely" />
              <FormControlLabel value="Unlikely" control={<Radio color="default"/>} label="Unlikely" />
              <FormControlLabel value="Very Unlikely" control={<Radio color="default"/>} label="Very Unlikely" />
            </RadioGroup>
            {
              this.state.returnLikelihoodError &&
              <FormHelperText>{this.state.returnLikelihoodError}</FormHelperText>            
            }            
          </FormControl>

            <br/>
            <br/>

          <FormControl error={this.state.isRegularCustomerError ? true : false} component="fieldset" className={classes.formControl}>
            <FormLabel component="legend" required>Are you a regular customer of {this.props.firstName}?</FormLabel>
            <RadioGroup
              name="isRegularCustomer"
              className={classes.group}
              value={this.state.isRegularCustomer}              
              onChange={this.handleInputOnChange}
              row
            >
              <FormControlLabel value="true" control={<Radio color="default"/>} label="Yes" />
              <FormControlLabel value="false" control={<Radio color="default"/>} label="No" />
            </RadioGroup>
            {
              this.state.isRegularCustomerError &&
              <FormHelperText>{this.state.isRegularCustomerError}</FormHelperText>            
            }
          </FormControl>

          <br/>
          <br/>

          {
            this.state.reviewerDisplayName &&
            <div>           
              <FormLabel component="legend">Your name</FormLabel><br/>
              {this.state.reviewerDisplayName}
            </div>
          }

          {
            !this.state.reviewerDisplayName &&
            <FormControl error={this.state.facebookAccessTokenError ? true : false} component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Verify your identify</FormLabel><br/>
              <FacebookLogin
                appId="2448328588579019"
                autoLoad={false}
                scope="public_profile,email"
                fields="first_name,last_name"
                isMobile={false}
                callback={this.handleFacebookResponse}
                version="4.0"
                returnScopes
                render={renderProps => (
                  <Typography variant="body1">
                    <Link component="button" onClick={renderProps.onClick} underline="always">
                      Use Facebook
                    </Link>
                  </Typography>
                )}
              />
              {
                this.state.facebookAccessTokenError &&
                <FormHelperText>{this.state.facebookAccessTokenError}</FormHelperText>            
              }              
            </FormControl>
          }

          </DialogContent>
          <DialogActions>
            <CircularProgress size={25} className={classes.progress} style={{visibility:this.state.submitInProgress ? 'visible' : 'hidden'}}/>
            <StandardButton color="secondary" onClick={this.onSubmitReview} >
              Submit
            </StandardButton>
            <StandardButton color="secondary" onClick={onClose} >
              Cancel
            </StandardButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  handleFacebookResponse = (response) => {
    console.log('response', response);

    if (response.grantedScopes.includes('email') &&
        response.grantedScopes.includes('public_profile')) {

      this.setState({
        facebookAccessToken: response.accessToken,
        reviewerDisplayName: response.first_name +
         (response.last_name ? ' ' + response.last_name.slice(0,1) + '.' : '')
      });

    }
  }
}

ReviewDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

export default withStyles(styles)(withMobileDialog()(ReviewDialog));
