import React from 'react';
import ViewProfile from './ViewProfile';
import { transformToViewModel } from './ViewProfile';
import * as MiscFunctions from '../functions/MiscFunctions';
import Page from '../components/Page'
import * as Constants from '../functions/Constants';

class ViewProfileViaDirectLink extends React.Component {

  constructor(props) {
    super(props);

    console.log('props', JSON.stringify(props));

    this.state = {
      isLoaded: false,
      userProfileViewModel: null,
      profileId: props.match.params.profileId,
      history: props.history
    };

    console.log("this.state", this.state);
  }

  componentDidMount() {
      fetch(
        Constants.HS_PROFILE_PUBLIC_API_BASE_URL + "/" + this.state.profileId
      )
      .then(response => {
        console.log("statusCode: " + response.status);
        if (response.status === 200) {
          return response.json();
        } else {
          this.state.history.replace("/");
          return null;
        }
      })
      .then(dataModel => {
        if (dataModel != null) {
          console.log("dataModel: " + JSON.stringify(dataModel));
          this.setState({userProfileViewModel: transformToViewModel(dataModel), isLoaded: true});
        }
      })
      .catch(err => console.log(err));
  }

  render() {

    if (!this.state.isLoaded) {
      return MiscFunctions.DISPLAY_SPINNER_IN_MIDDLE;
    } else {
        return (
          <Page history={this.props.history} showSignInLink={false}>
            <ViewProfile
              userProfileViewModel={this.state.userProfileViewModel}
                history={this.props.history}
                underSearchContext={this.state.underSearchContext}
            />
          </Page>
        )
    }
  }
}

ViewProfileViaDirectLink.propTypes = {
};

export default ViewProfileViaDirectLink;
