import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default withStyles(theme => ({
  root: {
    fontSize: theme.typography.fontSize,    
    textTransform: 'none',
    padding: theme.spacing(1, 2),    
    color: theme.palette.secondary.main,
    margin: theme.spacing.unit,
    '&:hover': {
      backgroundColor: 'white',
    }
  }
}))(Button);
