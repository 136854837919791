import * as loadImage from 'blueimp-load-image';
import * as Constants from '../functions/Constants';

export function handleUploadImage(event, maxWidth, callback) {

    
    event.persist();

    loadImage(event.target.files[0], (canvas) => {
        console.log('canvas.width', canvas.width);
        console.log('maxWidth', maxWidth);
        if (canvas.width > maxWidth) {
            console.log('reload image');            
            // reload the image with scaling down
            loadImage(event.target.files[0], (canvas2) => {
                canvas = canvas2;
                var dataUrl = canvas.toDataURL('image/jpeg', 1.0);
                console.log('dataUrl', dataUrl);
                callback(dataUrl);    
                },
                {orientation: true, maxWidth: maxWidth, contain: true, canvas: true}
            );
        } else {
            console.log('use original image');            
            var dataUrl = canvas.toDataURL('image/jpeg', 1.0);
            console.log('dataUrl', dataUrl);
            callback(dataUrl);    
        }
      },
      {orientation: true, canvas: true}
    );
}

export function getImageUrl(profileId, imageFileName) {
    return Constants.HS_IMAGES_BASE_URL + "/" + profileId + "/" + imageFileName
}

/*
export function extractBase64ImageFromDataUrl(dataUrl) {

    // dataUrl is of following format: data:image/jpeg;base64,<base64Image>

    var delimiter = ';base64,';
    var pos = dataUrl.indexOf(delimiter);
    var base64Image = dataUrl.slice(pos + delimiter.length);
    return base64Image;
}
*/