import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default withStyles(theme => ({
  root: {
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamilySecondary,
    fontSize: theme.typography.fontSize,    
    textTransform: 'none',    
//    padding: theme.spacing(1.5, 3),
    padding: theme.spacing(1, 2),    
    boxShadow: 'none',
    '&:active, &:focus': {
      boxShadow: 'none',
    },
    color: 'white',
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  sizeSmall: {
    padding: theme.spacing(1.5, 1.5),
    fontSize: theme.typography.pxToRem(13),
  },
  sizeLarge: {
    padding: theme.spacing(2, 5),
    fontSize: theme.typography.pxToRem(16),
  },
}))(Button);
