import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from './modules/components/Typography';
import AppForm from './modules/views/AppForm';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import StandardButton from './modules/components/StandardButton';
import Page from '../components/Page'
import * as Constants from '../functions/Constants';

const MISSING_FIELD_ERROR_MSG = "Required";

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      code: "",
      password: "",
      confirmPassword: "",

      codeError: "",
      passwordError: "",
      confirmPasswordError: "",

      submitInProgress: false,
      submitSucceeded: false
    };
  }

  render() {

    if (this.state.submitSucceeded) {
      return (
        <Page history={this.props.history} isVerticalCenter={true}>
          <AppForm>
            <Typography variant="h4" gutterBottom marked="center" align="center">
              Reset Password
            </Typography>
            <Typography variant="body2" align="center">
                {"Password reset successfully. "}
                <Link component="button" color="inherit" onClick={() => this.props.history.push('/signIn')} underline="always">
                  Sign in
                </Link>
            </Typography>
          </AppForm>
        </Page>
      )
    }

    return (
      <Page history={this.props.history} isVerticalCenter={true}>
        <AppForm>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Reset Password
          </Typography>
          <Typography variant="body2" align="center">
              {"An email was sent with a code to reset your password. "}
              <Link component="button" onClick={this.onResendCode} underline="always">
                Resend code
              </Link>
          </Typography>
          <br/>           
          <br/>
          <FormControl error={this.state.codeError ? true : false} fullWidth>
            <FormLabel htmlFor="code" required>Code</FormLabel>
            <TextField
                margin="normal"
                name="code"
                variant="outlined"
                value={this.state.code}
                onChange={this.handleInputOnChange}
                error={this.state.codeError ? true : false}                                                                          
                inputProps={{
                  maxLength: 100
                }}
                onKeyDown={this.onKeyDown}
            />
            {
              this.state.codeError &&
              <FormHelperText>{this.state.codeError}</FormHelperText>
            }
          </FormControl>              
          <br/>
          <br/>
          <FormControl error={this.state.passwordError ? true : false} fullWidth>
            <FormLabel htmlFor="password" required>New Password</FormLabel>
            <TextField
                margin="normal"
                name="password"
                variant="outlined"
                value={this.state.password}
                onChange={this.handleInputOnChange}
                error={this.state.passwordError ? true : false}                                                                          
                inputProps={{
                  maxLength: 100,
                  type: 'password'
                }}
                onKeyDown={this.onKeyDown}
            />
            {
              this.state.passwordError &&
              <FormHelperText>{this.state.passwordError}</FormHelperText>            
            }                
          </FormControl> 
          <br/>
          <br/>
          <FormControl error={this.state.confirmPasswordError ? true : false} fullWidth>
            <FormLabel htmlFor="confirmPassword" required>Confirm Password</FormLabel>
            <TextField
                margin="normal"
                name="confirmPassword"
                variant="outlined"
                value={this.state.confirmPassword}
                onChange={this.handleInputOnChange}
                error={this.state.confirmPasswordError ? true : false}                                                                          
                inputProps={{
                  maxLength: 100,
                  type: 'password'
                }}
                onKeyDown={this.onKeyDown}
            />
            {
              this.state.confirmPasswordError &&
              <FormHelperText>{this.state.confirmPasswordError}</FormHelperText>            
            }                
          </FormControl> 
          <br/>
          <br/>          
          <div style={{textAlign: 'right'}}>
            <CircularProgress size={25} style={{visibility:this.state.submitInProgress ? 'visible' : 'hidden'}}/>
            &nbsp;&nbsp;
            <StandardButton variant="contained" onClick={this.onResetPassword} >
              Reset Password
            </StandardButton>
          </div>
        </AppForm>
      </Page>
    );
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onResetPassword();
    }
  }

  handleInputOnChange = (event) => {

    const updatedFieldName = event.target.name;
    const updatedValue = event.target.value;

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    this.setState(state => ({
      [updatedFieldName]: updatedValue
    }))
  }

  onResetPassword = () => {

    let dataModel = {
      email: this.state.email,
      code: this.state.code,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword
    };

    var atLeastOneError = false;
    // Validation

    if (!dataModel.code) {
      this.setState({codeError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({codeError: ''});
    }

    if (!dataModel.password) {
      this.setState({passwordError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (dataModel.password.length < 8) {
      this.setState({passwordError: "Password must contain at least 8 characters"});
      atLeastOneError = true;      
    } else {
      this.setState({passwordError: ''});
    }

    if (!dataModel.confirmPassword) {
      this.setState({confirmPasswordError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (dataModel.confirmPassword != dataModel.password) {
      this.setState({confirmPasswordError: "Passwords do not match"});
      atLeastOneError = true;
    } else {
      this.setState({confirmPasswordError: ''});
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({submitInProgress: true});
  
    Auth.forgotPasswordSubmit(dataModel.email, dataModel.code, dataModel.password)
      .then(response => {
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          submitInProgress: false,
          codeError: "",
          passwordError: "",
          confirmPasswordError: "",          
          submitSucceeded: true
        });
      })
      .catch(err => {
        console.log("Error", err);
        if (err.code == "NetworkError") {
          this.setState({codeError: 'Network error'});
        } else if (err.code == "InvalidPasswordException") {
          this.setState({passwordError: err.message});
        } else if (err.code == "CodeMismatchException") {
          this.setState({codeError: err.message});
        } else if (err.code == "LimitExceededException") {
          // display error at the last field
          this.setState({confirmPasswordError: Constants.MESSAGE_RATE_LIMIT_EXCEEDED});
        } else {
          // display error at the last field
          this.setState({confirmPasswordError: Constants.MESSAGE_SYSTEM_ERROR});
        }
      
        this.setState({
          submitInProgress: false
        });
      });
  }

  onResendCode = () => {

    let dataModel = {
      email: this.state.email
    };

    this.setState({submitInProgress: true});

    console.log("dataModel: " + JSON.stringify(dataModel));

    Auth.forgotPassword(dataModel.email)
      .then(response => {
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          submitInProgress: false,
          codeError: ""
        });
      })
      .catch(err => {
        console.log("Error", err);
        // Use codeError instead of emailError as it doesn't exist
        if (err.code == "NetworkError") {
          this.setState({codeError: 'Network error'});
        }
        // we don't need to check user not confirmed or user not exist as these
        // scenarios would have been handled at ForgotPassword page before coming
        // to here
        else if (err.code == "LimitExceededException") {
          // display error at the last field
          this.setState({confirmPasswordError: Constants.MESSAGE_RATE_LIMIT_EXCEEDED});
        } else {
          // display error at the last field
          this.setState({confirmPasswordError: Constants.MESSAGE_SYSTEM_ERROR});
        }


        this.setState({
          submitInProgress: false
        });
      });
  }
}

export default ResetPassword;
