import React from 'react';
// eslint-disable-next-line
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports'; // if you are using Amplify CLI
import { Auth } from 'aws-amplify';
import ProtectedAppRoutes from './ProtectedAppRoutes';

Amplify.configure(awsconfig);

class ProtectedApp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loggedInStatus: "Unknown"
    };
  }

  componentDidMount() {
    Auth.currentSession({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(session => {      
      console.log(session);
      this.setState({loggedInStatus: "LoggedIn"});
    })
    .catch(err => {
      console.log("Err", err);
      this.setState({loggedInStatus: "NotLoggedIn"});
    });
  }

  render() {

    if (this.state.loggedInStatus == "Unknown") {
      return null;
    } else if (this.state.loggedInStatus == "LoggedIn")  {
      return <ProtectedAppRoutes/>;
    } else { // Not Logged-in
      this.props.history.replace("/signIn", {callbackUrl:this.props.location.pathname});
      return null;
    }
  }
}

export default ProtectedApp;