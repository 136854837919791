import React from 'react';
// eslint-disable-next-line
import { Switch, Route } from 'react-router-dom'
import EditProfile from './pages/EditProfile';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports'; // if you are using Amplify CLI
import ViewProfileByHairStylist from './pages/ViewProfileByHairStylist';
import SearchByHairStylist from './pages/SearchByHairStylist';
import ChangePassword from './pages/ChangePassword';

Amplify.configure(awsconfig);

class ProtectedAppRoutes extends React.Component {

  render() {

    return (
      <Switch>
          <Route exact path='/editProfile' component={EditProfile}/>               
          <Route path='/viewProfile' component={ViewProfileByHairStylist}/>
          <Route path='/searchByStylist' component={SearchByHairStylist}/>          
          <Route path='/changePassword' component={ChangePassword}/>               
      </Switch>
    );
  }
}

export default ProtectedAppRoutes;
