import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import withWidth from '@material-ui/core/withWidth';

import Avatar from '@material-ui/core/Avatar';

import Paper from '@material-ui/core/Paper';

import Divider from '@material-ui/core/Divider';
import * as Constants from '../functions/Constants';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PhotoManager from './PhotoManager';

import Portfolio from './Portfolio';

import TextField from '@material-ui/core/TextField';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fingerprint2 from 'fingerprintjs2';
import Rating from '@material-ui/lab/Rating';

import { handleUploadImage } from '../functions/ImageFunctions';
import { getImageUrl } from '../functions/ImageFunctions';
import * as MiscFunctions from '../functions/MiscFunctions';
import Typography from '../onepirate/modules/components/Typography';
import StandardButton from '../onepirate/modules/components/StandardButton';
import Page from '../components/Page';
import AppForm from '../onepirate/modules/views/AppForm';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

var hash = require('object-hash');

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  centre: {
    display:'flex', justifyContent:'space-around'
  },
  table: {
    maxWidth: 500,
  },
  headerRow: {
    backgroundColor: `#fafafa`
  },
  headerCell: {
    color: 'rgba(0, 0, 0, 0.5)',
    lineHeight: '1.3em'
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  editProfileDataSection: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px',
      marginRight: '20px',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
      maxWidth: '960px',
      margin: 'auto'
    }
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  photoInput: {
    display: 'none'
  },
  progress: {
    margin: theme.spacing(2),
  },
  workingDayCheckbox: {
    width:'70px'
  },
  checked: {
    //color: 'yellow'
  }
});

const coverPictureStyle = {
  width: '100%',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '200px'
};

const coverPictureStyleEmpty = {
  width: '100%',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '200px',
  lineHeight: '200px',    
  backgroundColor: 'black',
  textAlign: 'center',
  color: 'grey'
};

const MISSING_FIELD_ERROR_MSG = "Required";
const EMPTY_PROFILE_PICTURE = '/static/emptyProfilePicture.png';
const COVER_PICTURE_MAX_WIDTH = 1000;
const PROFILE_PICTURE_MAX_WIDTH = 500;

class EditProfile extends React.Component {

  constructor(props) {
    super(props);

    var isNewlyJoined = localStorage.getItem(Constants.LOCAL_STORAGE_KEY_IS_NEWLY_JOINED);
    localStorage.removeItem(Constants.LOCAL_STORAGE_KEY_IS_NEWLY_JOINED);
    sessionStorage.removeItem(Constants.SESSION_STORAGE_KEY_USER_PROFILE_DATA_WITH_TIMESTAMP);

    this.state = {
      isLoaded: false,
      photoIndex: 0,
      isOpen: false,
      profilePictureManagerOpen: false,
      coverPictureManagerOpen: false,
      displaySpinnerInMiddle: false,
      isNewlyJoined: isNewlyJoined,
      userProfile: {
        coverPicture: "",
        profilePicture: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        occupation: "Empty",
        salon: {
          name: "",
          phoneNumber: "",          
          addressLine: "",
          suburb: "",
          postcode: "",
          state: "Empty",
          website: "",
        },
        workingDays: [],
        employmentStatus: "Empty",
        biography: "",
        awards: "", // string instead of array for viewModel
        additionalSpokenLanguages: "", // string instead of array for viewModel,
        stats: {
          reviewsCount: 0,
          rating: null,
          regularCustomersCount: 0
        },
        portfolio: []
      },
      basicInfoModified: false,
      workRelatedInfoModified: false,
      additionalInfoModified: false,
      basicInfoUpdateInProgress: false,
      workRelatedInfoUpdateInProgress: false,
      additionalInfoUpdateInProgress: false,
      occupationError: "",
      salonNameError: "",
      salonPhoneNumberError: "",
      salonAddressLineError: "",
      salonSuburbError: "",
      salonPostcodeError: "",
      salonStateError: "",
      employmentStatusError: ""
    };
  } 

  uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

  retrieveBrowserId = () => {
    var browserId = localStorage.getItem("b");
    if (!browserId) {
      browserId = this.uuidv4();
      localStorage.setItem("b", browserId);
    }
    console.log('b', browserId);
    return browserId;
  }

  componentDidMount() {
    Auth.currentSession({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(session => {
      
      console.log(session);

      // Fingerprint generation needs to be done before GET profile
      // hence we do it here. If done inside constructor, GET profile
      // would not wait for fingerprint generation to finish hence 'undefined'
      // value
      Fingerprint2.get(components => {
        var browserFingerprint = hash(components);

        fetch(
          Constants.HS_PROFILE_PROTECTED_API_URL,
          {
            headers: new Headers(
            {
            'Authorization': session.getIdToken().getJwtToken(),
            'b': this.retrieveBrowserId(),            
            'bb': browserFingerprint
            })
          }
        )
        .then(response => response.json())
        .then(dataModel => {
          console.log("dataModel: " + dataModel);
          console.log("dataModel firstName: " + dataModel.firstName);
          let viewModel = this.transformToViewModel(dataModel);
          this.setState({userProfile: viewModel, isLoaded: true});
        })
        .catch(err => console.log(err));
      });

      })
      .catch(err => console.log(err));
  }

  transformToViewModel(dataModel) {
    let viewModel = {...this.state.userProfile, ...dataModel};

    // need to do again for salon and stats, as it's nested otherwise all attributes
    // in state.userProfile.salon/stats would be overriden or removed if no such attribute in dataModel
    viewModel = {
        ...viewModel,
       salon: {...this.state.userProfile.salon, ...dataModel.salon},
       stats: {...this.state.userProfile.stats, ...dataModel.stats}
    };

    console.log("viewModel: " + JSON.stringify(viewModel));

    viewModel.awards = this.arrayJoin(viewModel.awards, "\n");          
    viewModel.additionalSpokenLanguages = this.arrayJoin(viewModel.additionalSpokenLanguages, "\n");
    if (!viewModel.occupation) {
      viewModel.occupation = 'Empty';
    }
    if (!viewModel.salon.state) {
      viewModel.salon.state = 'Empty';
    }    
    if (!viewModel.employmentStatus) {
      viewModel.employmentStatus = 'Empty';
    }

    if (viewModel.profilePicture) {
      viewModel.profilePicture = getImageUrl(viewModel.id, viewModel.profilePicture);
    } else {
      viewModel.profilePicture = ''
    }

    if (viewModel.coverPicture) {
      viewModel.coverPicture = getImageUrl(viewModel.id, viewModel.coverPicture);
    } else {
      viewModel.coverPicture = ''
    }

    for (var i = 0; i < viewModel.portfolio.length; i++) {
      viewModel.portfolio[i].imageUrl = getImageUrl(viewModel.id, viewModel.portfolio[i].imageFile);
      delete viewModel.portfolio[i].imageFile; // remove imageFile for consistency as newly added imge doesn't have this info
    }

    console.log("viewModel: " + JSON.stringify(viewModel));
    return viewModel;
  }

  updateBasicInfo = () => {

    let dataModel = {
      occupation: this.state.userProfile.occupation === 'Empty' ? "" : this.state.userProfile.occupation,
      biography: this.state.userProfile.biography
    };

    // Validation
    if (!dataModel.occupation) {
      this.setState({occupationError: MISSING_FIELD_ERROR_MSG});
      return;
    } else {
      this.setState({occupationError: ''});
    }

    this.setState({basicInfoUpdateInProgress: true});
    this.updateProfile(dataModel, 'basicInfoModified');
  }

  updateWorkRelatedInfo = () => {

    let dataModel = {
      salon: this.state.userProfile.salon,
      employmentStatus: this.state.userProfile.employmentStatus,
      workingDays: this.state.userProfile.workingDays,
    };

    if (dataModel.salon.state === 'Empty') {
      dataModel.salon.state = "";
    }

    if (dataModel.employmentStatus === 'Empty') {
      dataModel.employmentStatus = "";
    }

    // Validation
    var workRelatedInfoHasAtLeastOneNonEmptyProperty = this.checkIfWorkRelatedInfoHasAtLeastOneNonEmptyProperty(dataModel);

    if (workRelatedInfoHasAtLeastOneNonEmptyProperty) {

      var atLeastOneError = false;

      if (!dataModel.salon.name) {
        this.setState({salonNameError: MISSING_FIELD_ERROR_MSG});
        atLeastOneError = true;
      } else {
        this.setState({salonNameError: ''});
      }

      if (!dataModel.salon.phoneNumber) {
        this.setState({salonPhoneNumberError: MISSING_FIELD_ERROR_MSG});
        atLeastOneError = true;
      } else if (dataModel.salon.phoneNumber.length < 8) {
        this.setState({salonPhoneNumberError: 'Invalid phone number'});
        atLeastOneError = true;
      } else {
        this.setState({salonPhoneNumberError: ''});
      }

      if (!dataModel.salon.addressLine) {
        this.setState({salonAddressLineError: MISSING_FIELD_ERROR_MSG});
        atLeastOneError = true;
      } else {
        this.setState({salonAddressLineError: ''});
      }

      if (!dataModel.salon.suburb) {
        this.setState({salonSuburbError: MISSING_FIELD_ERROR_MSG});
        atLeastOneError = true;
      } else {
        this.setState({salonSuburbError: ''});
      }

      if (!dataModel.salon.postcode) {
        this.setState({salonPostcodeError: MISSING_FIELD_ERROR_MSG});
        atLeastOneError = true;
      } else if (dataModel.salon.postcode.length !== 4) {
        this.setState({salonPostcodeError: 'Invalid postcode'});
        atLeastOneError = true;
      } else {
        this.setState({salonPostcodeError: ''});
      }

      if (!dataModel.salon.state) {
        this.setState({salonStateError: MISSING_FIELD_ERROR_MSG});
        atLeastOneError = true;
      } else {
        this.setState({salonStateError: ''});
      }

      if (!dataModel.employmentStatus) {
        this.setState({employmentStatusError: MISSING_FIELD_ERROR_MSG});
        atLeastOneError = true;
      } else {
        this.setState({employmentStatusError: ''});
      }

      if (atLeastOneError) {
        return;
      }
    } else {
      this.setState(
        {
          salonNameError: "",
          salonPhoneNumberError: "",
          salonAddressLineError: "",
          salonSuburbError: "",
          salonPostcodeError: "",
          salonStateError: "",
          employmentStatusError: ""              
        }
      )
    }

    this.setState({workRelatedInfoUpdateInProgress: true});
    this.updateProfile(dataModel, 'workRelatedInfoModified');
  }

  checkIfWorkRelatedInfoHasAtLeastOneNonEmptyProperty = (dataModel) => {
    if (dataModel.salon.name || dataModel.salon.phoneNumber || dataModel.salon.addressLine ||
        dataModel.salon.suburb || dataModel.salon.postcode || dataModel.salon.state ||
        dataModel.salon.website || dataModel.employmentStatus ||
        (dataModel.workingDays && dataModel.workingDays.length !== 0)) {
        return true;
    }
    return false;
  }

  updateAdditionalInfo = () => {

    this.setState({additionalInfoUpdateInProgress: true});

    let dataModel = {
      awards: this.state.userProfile.awards,
      additionalSpokenLanguages: this.state.userProfile.additionalSpokenLanguages
    };

    dataModel.awards = dataModel.awards.split("\n");
    dataModel.additionalSpokenLanguages = dataModel.additionalSpokenLanguages.split("\n");

    this.updateProfile(dataModel, 'additionalInfoModified');
  }

  updateProfile = (dataModel, infoModifiedStateName, onSuccess) => {

    console.log("dataModel: " + JSON.stringify(dataModel));

    Auth.currentSession({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(session => {
      
      console.log(session);

      fetch(
        Constants.HS_PROFILE_PROTECTED_API_URL,
        {
          headers: new Headers(
          {
            'Authorization': session.getIdToken().getJwtToken()
          }),
          method: "PUT",
          body: JSON.stringify(dataModel)
        }
      )
      .then(response => {
        console.log("statusCode: " + response.status);
        if (response.status === 200) {
          if (onSuccess) {
            onSuccess(session.getIdToken().payload.sub);
          }
          if (infoModifiedStateName) {
            this.setState({
              [infoModifiedStateName]: false
            })
          }
        }
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          basicInfoUpdateInProgress: false,
          workRelatedInfoUpdateInProgress: false,
          additionalInfoUpdateInProgress: false
        });
      })
      .catch(err => {
        console.log("Error", err);      
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          basicInfoUpdateInProgress: false,
          workRelatedInfoUpdateInProgress: false,
          additionalInfoUpdateInProgress: false
        });
      })
      .catch(err => console.log(err));
    })
  }

  arrayJoin (array, separator) {
    return Array.isArray(array) && array.length > 0
      ? array.reduce((result, item) => result + separator + item)
      : "";
  }

  handleInputOnChange = (event) => {

    const updatedUserProfileFieldName = event.target.name;
    const updatedValue = event.target.value;
    console.log("updatedUserProfileFieldName: " + updatedUserProfileFieldName);
    console.log("updatedValue: " + updatedValue);

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    var infoModifiedStateName;
    if (updatedUserProfileFieldName === "occupation" ||
      updatedUserProfileFieldName === "biography") {
        infoModifiedStateName = "basicInfoModified";
    }

    if (updatedUserProfileFieldName === "employmentStatus") {
      infoModifiedStateName = "workRelatedInfoModified";
    }

    if (updatedUserProfileFieldName === "awards" ||
        updatedUserProfileFieldName === "additionalSpokenLanguages") {
          infoModifiedStateName = "additionalInfoModified";
    }

    if (!this.state[infoModifiedStateName]) {
      this.setState({[infoModifiedStateName]: true});
    }

    this.setState(state => ({
      userProfile: {...state.userProfile, [updatedUserProfileFieldName]: updatedValue}
    }))
  }

  handleSalonInputOnChange = (event) => {

    const updatedSalonFieldName = event.target.name;
    const updatedValue = event.target.value;
    console.log("updatedSalonFieldName: " + updatedSalonFieldName);
    console.log("updatedValue: " + updatedValue);

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    if (!this.state.workRelatedInfoModified) {
      this.setState({workRelatedInfoModified: true});
    }

    this.setState(state => ({
      userProfile: {...state.userProfile, salon: {...state.userProfile.salon, [updatedSalonFieldName]: updatedValue}}
    }))
  }

  handleWorkingDaysOnChange = (event) => {

    const updatedWorkingDay = event.target.name;
    const updatedValue = event.target.value;
    console.log("updatedWorkingDay: " + updatedWorkingDay);
    console.log("updatedValue: " + updatedValue);  

    if (!this.state.workRelatedInfoModified) {
      this.setState({workRelatedInfoModified: true});
    }

    this.setState(state => {

      let updatedWorkingDays;
      if (state.userProfile.workingDays.includes(updatedWorkingDay)) {
        updatedWorkingDays = state.userProfile.workingDays.filter((item, j) => item !== updatedWorkingDay);
      } else {
        updatedWorkingDays = state.userProfile.workingDays.concat(updatedWorkingDay);        
      }
      return {
        userProfile: {...state.userProfile,
        workingDays: updatedWorkingDays}
        }
      });
  }

  render() {

    if (!this.state.isLoaded) {
      return MiscFunctions.DISPLAY_SPINNER_IN_MIDDLE;
    }

    const { classes } = this.props;

    return (
      <Page history={this.props.history} showMenu={true}>

        {
          this.state.displaySpinnerInMiddle &&
          MiscFunctions.DISPLAY_SPINNER_IN_MIDDLE
        }

      {/********** Cover Picture **************/}
      {
          this.state.userProfile.coverPicture &&
          <div style={{padding: '15px'}}>
            <div
              style={{backgroundImage: `url(${this.state.userProfile.coverPicture})`,...coverPictureStyle}}
              onClick={() => this.setState({ coverPictureManagerOpen: true })}
            />
          </div>
      }

      {
            !this.state.userProfile.coverPicture &&
            <div style={{padding: '15px'}}>
              <label htmlFor="icon-button-add-cover-picture">
                <div style={coverPictureStyleEmpty}>
                  <AddAPhotoIcon style={{verticalAlign: 'text-bottom'}}/>&nbsp;&nbsp;Add cover photo
                </div>
                <input
                accept="image/*"
                className={classes.photoInput}
                id="icon-button-add-cover-picture"
                onChange={ (event) => handleUploadImage(event, COVER_PICTURE_MAX_WIDTH, this.handleCoverPictureOnChange) }              
                type="file"
                />
              </label>
            </div>
      }

      {
            this.state.coverPictureManagerOpen &&
            <PhotoManager
              photo={this.state.userProfile.coverPicture}
              onPhotoReplaced={this.handleCoverPictureOnChange}
              onPhotoDeleted={this.handleCoverPictureOnDeleted}
              onClose={() => this.setState({coverPictureManagerOpen: false})}
              deletePrompt={"Delete cover picture?"}
              uploadedImageMaxWith={COVER_PICTURE_MAX_WIDTH}
              />
      }

      {/********** Profile Picture **************/}

      <div className={classes.centre}>

          {
            this.state.userProfile.profilePicture &&
            <Avatar
              alt="Profile Picture"
              onClick={() => this.setState({ profilePictureManagerOpen: true })}
              src={this.state.userProfile.profilePicture}
              style={{width: 100, height: 100, top: -50}}
            />
          }

          {
            !this.state.userProfile.profilePicture &&
            <label htmlFor="icon-button-add-profile-picture">

              <Avatar
                alt="Profile Picture"
                src={EMPTY_PROFILE_PICTURE}
                style={{width: 80, height: 80, top: -50}}
              />
              <input
              accept="image/*"
              className={classes.photoInput}
              id="icon-button-add-profile-picture"
              onChange={ (event) => handleUploadImage(event, PROFILE_PICTURE_MAX_WIDTH, this.handleProfilePictureOnChange) }
              type="file"
              />
            </label>
          }

          {
            this.state.profilePictureManagerOpen &&
            <PhotoManager
              photo={this.state.userProfile.profilePicture}
              onPhotoReplaced={this.handleProfilePictureOnChange}
              onPhotoDeleted={this.handleProfilePictureOnDeleted}
              onClose={() => this.setState({profilePictureManagerOpen: false})}
              deletePrompt={"Delete profile picture?"}
              uploadedImageMaxWith={PROFILE_PICTURE_MAX_WIDTH}
              />
          }

      </div>

      {
        <Snackbar
          open={this.state.isNewlyJoined}
          autoHideDuration={6000}
          onClose={() => this.setState({isNewlyJoined: false})}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity="success"
            onClose={() => this.setState({isNewlyJoined: false})}>
              Profile created
            </Alert>
        </Snackbar>
      }

      <div className={classes.centre}>
          <Typography variant="h5">
            {this.state.userProfile.firstName}&nbsp;{this.state.userProfile.lastName}
          </Typography>
      </div>
      
      {/********** Statistics **************/}

      {/*
      <div className={classes.centre}>
      <Paper>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell align="center" className={classes.headerCell}>Reviews</TableCell>
            <TableCell align="center" className={classes.headerCell}>Rating</TableCell>
            <TableCell align="center" className={classes.headerCell}>Regular Customers</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key="data">
              <TableCell align="center" component="th" scope="row">
                <span style={{verticalAlign: 'top'}}>
                  {this.state.userProfile.stats.reviewsCount}
                </span>
                <br/>
                &nbsp;
                </TableCell>
                <TableCell align="center">
                <span style={{verticalAlign: 'top'}}>              
                  {this.state.userProfile.stats.rating == null ? 'N/A' : this.state.userProfile.stats.rating}                
                </span>
                <br/>
                <span style={{verticalAlign: 'top'}}>
                  {this.state.userProfile.stats.rating == null ? "" : <Rating value={this.state.userProfile.stats.rating} precision={0.1} size="small" readOnly/>}&nbsp;
                </span>
                </TableCell>
                <TableCell align="center">
                <span style={{verticalAlign: 'top'}}>
                  {this.state.userProfile.stats.regularCustomersCount}
                  </span>
                <br/>
                &nbsp;
                </TableCell>
            </TableRow>
        </TableBody>
      </Table>
      </Paper>
      </div>
      
      <br/>
      <br/>
      */}

      <br/>
      {/********** Personal Details **************/}

      <div className={classes.editProfileDataSection}>

        <Divider className={classes.divider}/>

          <Typography color="secondary" align="center">
                  Personal Details
          </Typography>

        <br/>
        {/*
        <div>
          <div style={{display: 'inline-block', width: '40%'}}>
            <TextField
                    margin="normal"
                    name="firstName"
                    label="First Name"
                    value={this.state.userProfile.firstName}
                    disabled
            />
          </div>

          <div style={{display: 'inline-block', width: '40%', marginLeft: '20px'}}>
            <TextField
                    margin="normal"
                    name="lastName"
                    label="Last Name"
                    value={this.state.userProfile.lastName}
                    disabled
            />
          </div>
        </div>
        */}

{/*
      <div style={{width: '400px'}}>
        <div style={{display: 'inline-block', marginLeft: '20px', width: '40%'}}>
          <TextField
                  margin="normal"
                  id="gender"
                  label="Gender"
                  value="Male"
                  disabled
          />
        </div>
      </div>
*/}
{/*
        <div>
          <TextField
                  margin="normal"
                  name="emailAddress"
                  label="Email Address"
                  value={this.state.userProfile.emailAddress}
                  disabled
                  fullWidth
          />
        </div>

        <div>
          <TextField
                  margin="normal"
                  name="mobile"
                  label="Mobile"
                  value={this.state.userProfile.mobileNumber}
                  disabled
          />
        </div>
*/}
      <AppForm noVerticalPadding>
        <div>
          <FormControl error={this.state.occupationError ? true : false} fullWidth>
            <InputLabel htmlFor="occupation" required>Occupation</InputLabel>
            <br/>
            <Select
              value={this.state.userProfile.occupation}
              onChange={this.handleInputOnChange}                
              inputProps={{
                name: 'occupation'
              }}
            >
              <MenuItem value="Empty">Please select</MenuItem>
              <MenuItem value="Hair Stylist">Hair Stylist</MenuItem>
              <MenuItem value="Barber">Barber</MenuItem>
              <MenuItem value="Apprentice">Apprentice</MenuItem>
              <MenuItem value="Colourist">Colourist</MenuItem>
            </Select>
            {
              this.state.occupationError &&
              <FormHelperText>{this.state.occupationError}</FormHelperText>            
            }
          </FormControl>
        </div>

        <br/>
        <br/>

        <div>
        <FormControl fullWidth>
          <FormLabel htmlFor="biography">Biography</FormLabel>
          <TextField
                margin="dense"
                name="biography"
                multiline
                rows="6"
                variant="outlined"
                value={this.state.userProfile.biography}
                onChange={this.handleInputOnChange}    
                inputProps={{
                  maxLength: 500
                }}
            />
        </FormControl>            
        </div>

        <div style={{textAlign:'right'}}>
          <CircularProgress size={25} className={classes.progress} style={{visibility:this.state.basicInfoUpdateInProgress ? 'visible' : 'hidden'}}/>
          <StandardButton size="small" variant="contained" onClick={this.updateBasicInfo} disabled={!this.state.basicInfoModified}>
            Update
          </StandardButton>
        </div>
        
        </AppForm>
        <Divider className={classes.divider}/>

        <Typography color="secondary" align="center">
          Salon
        </Typography>

        <AppForm noVerticalPadding>
        <div>
          <TextField
                margin="normal"
                name="name"
                label="Name"
                fullWidth
                value={this.state.userProfile.salon.name}
                onChange={this.handleSalonInputOnChange}
                error={this.state.salonNameError ? true : false}                                                                          
                helperText={this.state.salonNameError}
                inputProps={{
                  maxLength: 100,
                  size: 30
                }}                                                   
          />
        </div>

        <div>
          <TextField
                  margin="normal"
                  name="phoneNumber"
                  label="Phone Number"
                  type="number"
                  fullWidth
                  value={this.state.userProfile.salon.phoneNumber}
                  onChange={this.handleSalonInputOnChange}                                                        
                  error={this.state.salonPhoneNumberError ? true : false}                                                                          
                  helperText={this.state.salonPhoneNumberError}
                  inputProps={{
                    maxLength: 10
                  }}
          />
        </div>

        <div>
          <TextField
                  margin="normal"
                  name="addressLine"
                  label="Address Line"
                  value={this.state.userProfile.salon.addressLine}
                  onChange={this.handleSalonInputOnChange}                                      
                  fullWidth
                  error={this.state.salonAddressLineError ? true : false}                                                                          
                  helperText={this.state.salonAddressLineError}
                  inputProps={{
                    maxLength: 100
                  }}
          />
        </div>
        <div>
          <TextField
                    margin="normal"
                    name="suburb"
                    label="Suburb"
                    fullWidth
                    value={this.state.userProfile.salon.suburb}                    
                    onChange={this.handleSalonInputOnChange}   
                    error={this.state.salonSuburbError ? true : false}                                                                          
                    helperText={this.state.salonSuburbError}
                    inputProps={{
                      maxLength: 100
                    }}                                                                           
            />
        </div>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={6}>
            <FormControl fullWidth>
              <TextField
                    margin="normal"
                    name="postcode"
                    label="Postcode"
                    type="number"
                    value={this.state.userProfile.salon.postcode} 
                    onChange={this.handleSalonInputOnChange}    
                    error={this.state.salonPostcodeError ? true : false}                                                                          
                    helperText={this.state.salonPostcodeError}
                    inputProps={{
                      maxLength: 4
                    }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6}>
            <FormControl error={this.state.salonStateError ? true : false} fullWidth margin="normal">
              <InputLabel htmlFor="salonState">State</InputLabel>
              <Select
                onChange={this.handleSalonInputOnChange}
                value={this.state.userProfile.salon.state}
                inputProps={{
                  name: 'state'
                }}
              >
                <MenuItem value="Empty">Please select</MenuItem>
                <MenuItem value="ACT">ACT</MenuItem>
                <MenuItem value="NSW">NSW</MenuItem>
                <MenuItem value="NT">NT</MenuItem>
                <MenuItem value="QLD">QLD</MenuItem>
                <MenuItem value="SA">SA</MenuItem>
                <MenuItem value="TAS">TAS</MenuItem>
                <MenuItem value="WA">WA</MenuItem>
                <MenuItem value="VIC">VIC</MenuItem>
              </Select>
              {
                this.state.salonStateError &&
                <FormHelperText>{this.state.salonStateError}</FormHelperText>            
              }
            </FormControl>
          </Grid>
        </Grid>

        <div>
          <TextField
                  margin="normal"
                  name="website"
                  label="Website"
                  value={this.state.userProfile.salon.website}
                  fullWidth
                  onChange={this.handleSalonInputOnChange}
                  inputProps={{
                    maxLength: 100
                  }}                                    
          />
        </div>

        <div>
          <FormControl error={this.state.employmentStatusError ? true : false} fullWidth margin="normal">    
              <InputLabel htmlFor="employmentStatus">Employment Status</InputLabel>
              <Select
                onChange={this.handleInputOnChange}
                value={this.state.userProfile.employmentStatus}
                inputProps={{
                  name: 'employmentStatus'
                }}
              >
                <MenuItem value="Empty">Please select</MenuItem>
                <MenuItem value="Employed">Employed</MenuItem>
                <MenuItem value="Self Employed">Self Employed</MenuItem>
                <MenuItem value="Salon Owner">Salon Owner</MenuItem>
              </Select>
              {
                this.state.employmentStatusError &&
                <FormHelperText>{this.state.employmentStatusError}</FormHelperText>            
              }
          </FormControl>
        </div>
        
        <div>
          <FormControl fullWidth margin="normal">
            <FormLabel htmlFor="workingDays">Your working days</FormLabel>
            <FormGroup
              name="workingDays"
              className={classes.group}
              row
            >

              <FormControlLabel
                control={
                  <Checkbox
                    name="Mon"
                    checked={this.state.userProfile.workingDays.includes("Mon")}
                    onChange={this.handleWorkingDaysOnChange}
                    color="default"                    
                  />
                }
                label="Mon"
                className={classes.workingDayCheckbox}
              />

            <FormControlLabel
                control={
                  <Checkbox
                    name="Tue"
                    checked={this.state.userProfile.workingDays.includes("Tue")}
                    onChange={this.handleWorkingDaysOnChange}
                    color="default"                    
                  />
                }
                label="Tue"
                className={classes.workingDayCheckbox}                
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="Wed"
                    checked={this.state.userProfile.workingDays.includes("Wed")}
                    onChange={this.handleWorkingDaysOnChange}
                    color="default"                    
                />
                }
                label="Wed"
                className={classes.workingDayCheckbox}                
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="Thu"
                    checked={this.state.userProfile.workingDays.includes("Thu")}
                    onChange={this.handleWorkingDaysOnChange}
                    color="default"                    
                  />
                }
                label="Thu"
                className={classes.workingDayCheckbox}                
              />

            <FormControlLabel
                control={
                  <Checkbox
                    name="Fri"
                    checked={this.state.userProfile.workingDays.includes("Fri")}
                    onChange={this.handleWorkingDaysOnChange}
                    color="default"
                  />
                }
                label="Fri"
                className={classes.workingDayCheckbox}                
              />

            <FormControlLabel
                control={
                  <Checkbox
                    name="Sat"
                    checked={this.state.userProfile.workingDays.includes("Sat")}
                    onChange={this.handleWorkingDaysOnChange}
                    color="default"                    
                  />
                }
                label="Sat"
                className={classes.workingDayCheckbox}                
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="Sun"
                    checked={this.state.userProfile.workingDays.includes("Sun")}
                    onChange={this.handleWorkingDaysOnChange}
                    color="default"
                    classes={{checked: classes.checked}}                    
                  />
                }
                label="Sun"
                className={classes.workingDayCheckbox}                
              />

            </FormGroup>
          </FormControl>

        </div>

        <div style={{textAlign:'right'}}>
          <CircularProgress size={25} className={classes.progress} style={{visibility:this.state.workRelatedInfoUpdateInProgress ? 'visible' : 'hidden'}}/>
          <StandardButton size="small" variant="contained" onClick={this.updateWorkRelatedInfo} disabled={!this.state.workRelatedInfoModified}>
            Update
          </StandardButton>
        </div>
        </AppForm>

        <Divider className={classes.divider}/>

        <Typography color="secondary" align="center">
          Additional Information
        </Typography>

        <br/>

        <AppForm noVerticalPadding>
          <div>
          <InputLabel htmlFor="awards">Awards</InputLabel>
          <TextField
                margin="dense"
                name="awards"
                multiline
                rows="4"
                fullWidth
                variant="outlined"
                value={this.state.userProfile.awards}
                onChange={this.handleInputOnChange}
                inputProps={{
                  maxLength: 500
                }}                
              />
          </div>

          <br/>
          <br/>

        <div>
          <InputLabel htmlFor="additionalSpokenLanguages">Additional Spoken Languages</InputLabel>
          <TextField
                margin="dense"
                name="additionalSpokenLanguages"
                multiline
                rows="4"
                fullWidth
                variant="outlined"
                value={this.state.userProfile.additionalSpokenLanguages}
                onChange={this.handleInputOnChange}
                inputProps={{
                  maxLength: 500
                }}                  
                placeholder="Languages other than English"                
          />
        </div>

        <div style={{textAlign:'right'}}>
          <CircularProgress size={25} className={classes.progress} style={{visibility:this.state.additionalInfoUpdateInProgress ? 'visible' : 'hidden'}}/>
          <StandardButton size="small" variant="contained" onClick={this.updateAdditionalInfo} disabled={!this.state.additionalInfoModified}>
            Update
          </StandardButton>
        </div>
        </AppForm>
      </div>

    <Divider variant="middle" className={classes.divider}/>
    <div className={classes.root}>
        <Portfolio
          portfolio={this.state.userProfile.portfolio}
          readOnly={false}
        />
    </div>

    <br/>
  </Page>
      
    );
  }

  handleProfilePictureOnChange = (dataUrl) => {

    this.setState(state => ({
      displaySpinnerInMiddle: true,
      profilePictureManagerOpen: false,
    }));

    var dataModel = {
      profilePictureDataUrl: dataUrl
    };

    this.updateProfile(dataModel, null, (profileId) => {

      this.setState(state => ({
        userProfile: {...state.userProfile, profilePicture: dataUrl},
        displaySpinnerInMiddle: false
      }));
    });
  }

  handleProfilePictureOnDeleted = () => {

    this.setState(state => ({
      displaySpinnerInMiddle: true,
      profilePictureManagerOpen: false,
    }));

    var dataModel = {
      profilePictureDataUrl: null
    };

    this.updateProfile(dataModel, null, (profileId) => {

      this.setState(state => ({
        userProfile: {...state.userProfile, profilePicture: null},
        displaySpinnerInMiddle: false
      }));
    });
  }

  handleCoverPictureOnChange = (dataUrl) => {

    this.setState(state => ({
      displaySpinnerInMiddle: true,
      coverPictureManagerOpen: false,
    }));

    var dataModel = {
      coverPictureDataUrl: dataUrl
    };

    this.updateProfile(dataModel, null, (profileId) => {

      this.setState(state => ({
        userProfile: {...state.userProfile, coverPicture: dataUrl},
        displaySpinnerInMiddle: false
      }));
    });
  }

  handleCoverPictureOnDeleted = () => {

    this.setState(state => ({
      displaySpinnerInMiddle: true,
      coverPictureManagerOpen: false
    }));

    var dataModel = {
      coverPictureDataUrl: null
    };

    this.updateProfile(dataModel, null, (profileId) => {

      this.setState(state => ({
        userProfile: {...state.userProfile, coverPicture: null},
        displaySpinnerInMiddle: false
      }));
    });
  }
}

EditProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(EditProfile));
