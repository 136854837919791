import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../components/Paper';

const styles = theme => ({
  paper: {
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 6),
    }
  },
  paperNoVerticalPadding: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 1),
    }
  }
});

function AppForm(props) {
  const { children, classes, noVerticalPadding } = props;

  return (

    <div>
      <Container maxWidth="sm">
        {/*<Box mt={7} mb={12}>*/}
                <Box mt={1} mb={1}>
          {
            props.useThemeColor &&
            <Paper style={{backgroundColor:'white'}} className={noVerticalPadding ? classes.paperNoVerticalPadding : classes.paper}>{children}</Paper>            
          }

          {
            !props.useThemeColor &&
            <Paper className={noVerticalPadding ? classes.paperNoVerticalPadding : classes.paper}>{children}</Paper>            
          }

        </Box>
      </Container>
    </div>
  );
}

AppForm.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppForm);
