
// --- Post bootstrap -----
import React from 'react';
import Typography from '../onepirate/modules/components/Typography';
import AppForm from '../onepirate/modules/views/AppForm';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import StandardButton from '../onepirate/modules/components/StandardButton';
import Page from '../components/Page';
import * as Constants from '../functions/Constants';

const MISSING_FIELD_ERROR_MSG = "Required";

class ChangePassword extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",

      oldPasswordError: "",
      newPasswordError: "",
      confirmPasswordError: "",      

      submitInProgress: false,
      submitSucceeded: false
    };
  }

render() {

  if (this.state.submitSucceeded) {
    return (

      <Page history={this.props.history} showMenu={true}>
        <AppForm>
          <React.Fragment>
            <Typography variant="h4" gutterBottom marked="center" align="center">
            Change Password
            </Typography>
            <Typography variant="body2" align="center">
                {"Password changed successfully. "}
            </Typography>
          </React.Fragment>
        </AppForm>
      </Page>
    )
  }

  return (
    <Page history={this.props.history} isVerticalCenter={true} showMenu={true}>
      <AppForm>
        <React.Fragment>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Change Password
          </Typography>
          <Typography variant="body2" align="center">
              {"Enter your current and new passwords. "}
          </Typography>
        </React.Fragment>
          <br/>           
          <FormControl error={this.state.oldPasswordError ? true : false} fullWidth>
            <FormLabel htmlFor="oldPassword" required>Current Password</FormLabel>
            <TextField
                margin="normal"
                name="oldPassword"
                variant="outlined"
                style={{backgroundColor: 'white'}}
                value={this.state.oldPassword}
                onChange={this.handleInputOnChange}
                error={this.state.oldPasswordError ? true : false}                                                                          
                inputProps={{
                  maxLength: 100,
                  type: 'password'                  
                }}
                onKeyDown={this.onKeyDown}
            />
            {
              this.state.oldPasswordError &&
              <FormHelperText>{this.state.oldPasswordError}</FormHelperText>
            }
          </FormControl>              
          <br/>
          <br/>
          <FormControl error={this.state.newPasswordError ? true : false} fullWidth>
            <FormLabel htmlFor="newPassword" required>New Password</FormLabel>
            <TextField
                margin="normal"
                name="newPassword"
                variant="outlined"
                style={{backgroundColor: 'white'}}
                value={this.state.newPassword}
                onChange={this.handleInputOnChange}
                error={this.state.newPasswordError ? true : false}                                                                          
                inputProps={{
                  maxLength: 100,
                  type: 'password'
                }}
                onKeyDown={this.onKeyDown}
            />
            {
              this.state.newPasswordError &&
              <FormHelperText>{this.state.newPasswordError}</FormHelperText>            
            }                
          </FormControl> 
          <br/>
          <br/>
          <FormControl error={this.state.confirmPasswordError ? true : false} fullWidth>
            <FormLabel htmlFor="confirmPassword" required>Confirm Password</FormLabel>
            <TextField
                margin="normal"
                name="confirmPassword"
                variant="outlined"
                style={{backgroundColor: 'white'}}
                value={this.state.confirmPassword}
                onChange={this.handleInputOnChange}
                error={this.state.confirmPasswordError ? true : false}                                                                          
                inputProps={{
                  maxLength: 100,
                  type: 'password'
                }}
                onKeyDown={this.onKeyDown}
            />
            {
              this.state.confirmPasswordError &&
              <FormHelperText>{this.state.confirmPasswordError}</FormHelperText>            
            }                
          </FormControl> 
          <br/>
          <br/>          
          <div style={{textAlign: 'right'}}>
            <CircularProgress size={25} style={{visibility:this.state.submitInProgress ? 'visible' : 'hidden'}}/>
            &nbsp;&nbsp;
            <StandardButton onClick={this.onChangePassword} >
              Change Password
            </StandardButton>
          </div>
      </AppForm>
    </Page>
  );
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onChangePassword();
    }
  }

  handleInputOnChange = (event) => {

    const updatedFieldName = event.target.name;
    const updatedValue = event.target.value;

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    this.setState(state => ({
      [updatedFieldName]: updatedValue
    }))
  }

  onChangePassword = () => {

    let dataModel = {
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword
    };

    var atLeastOneError = false;
    // Validation

    if (!dataModel.oldPassword) {
      this.setState({oldPasswordError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({oldPasswordError: ''});
    }

    if (!dataModel.newPassword) {
      this.setState({newPasswordError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (dataModel.newPassword.length < 8) {
      this.setState({newPasswordError: "Password must contain at least 8 characters"});
      atLeastOneError = true;      
    } else {
      this.setState({newPasswordError: ''});
    }

    if (!dataModel.confirmPassword) {
      this.setState({confirmPasswordError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (dataModel.confirmPassword != dataModel.newPassword) {
      this.setState({confirmPasswordError: "Passwords do not match"});
      atLeastOneError = true;
    } else {
      this.setState({confirmPasswordError: ''});
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({submitInProgress: true});

    console.log("dataModel: " + JSON.stringify(dataModel));
  
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      console.log("user", user);
      this.invokeChangePasswordApi(user, dataModel);
    })
    .catch(err => console.log(err));    
  }

  invokeChangePasswordApi = (user, dataModel) => {
    Auth.changePassword(user, dataModel.oldPassword, dataModel.newPassword)
      .then(response => {
        console.log("response: " + response);
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          submitInProgress: false,
          oldPasswordError: "",
          newPasswordError: "",
          confirmPasswordError: "",          
          submitSucceeded: true
        });
      })
      .catch(err => {
        console.log("Error", err);
        if (err.code == "NetworkError") {
          this.setState({oldPasswordError: 'Network error'});
        } else if (err.code == "InvalidPasswordException") {
          this.setState({newPasswordError: err.message});
        } else if (err.code == "NotAuthorizedException") {
          this.setState({oldPasswordError: "Invalid password"});
        } else if (err.code == "LimitExceededException") {
          // display error at the last field
          this.setState({codeError: Constants.MESSAGE_SYSTEM_ERROR});
        } else {
          // display error at the last field
          this.setState({codeError: Constants.MESSAGE_SYSTEM_ERROR});
        }

        this.setState({
          submitInProgress: false
        });
      });
  }
}

export default ChangePassword;


