import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from './modules/components/Typography';
import AppForm from './modules/views/AppForm';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import StandardButton from './modules/components/StandardButton';
import Page from '../components/Page'
import * as Constants from '../functions/Constants';
import { ConsoleLogger } from '@aws-amplify/core';

const MISSING_FIELD_ERROR_MSG = "Required";

// This module acts both as a page and a component
// Sign-In and Forgot-Password could redirect to this page
// On the other hand, Sign-Up could use this as a component to pass sensitive information
// i.e. password
class SignUpVerifyCode extends React.Component {

  constructor(props) {
    super(props);

    var username, password;

    if (props.location && props.location.state && props.location.state.username) {
      username = props.location.state.username;
      password = null;
    } else {
      username = props.username;
      password = props.password; 
    }

    this.state = {
      username: username,
      password: password,
      code: "",

      codeError: "",

      submitInProgress: false
    };
  }

  render() {

    return (
      <Page history={this.props.history} isVerticalCenter={true}>
        <AppForm>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Enter Verification Code
          </Typography>
          <Typography variant="body2" align="center">
              {"An email was sent with a verification code. "}
              <Link component="button" onClick={this.onResendCode} underline="always">
                Resend code
              </Link>
          </Typography>
          <br/>           
          <br/>
          <FormControl error={this.state.codeError ? true : false} fullWidth>
            <FormLabel htmlFor="code" required>Code</FormLabel>
            <TextField
                margin="normal"
                name="code"
                variant="outlined"
                value={this.state.code}
                onChange={this.handleInputOnChange}
                error={this.state.codeError ? true : false}                                                                          
                inputProps={{
                  maxLength: 100
                }}
                onKeyDown={this.onKeyDown}
            />
            {
              this.state.codeError &&
              <FormHelperText>{this.state.codeError}</FormHelperText>
            }
          </FormControl>              
          <br/>
          <br/>
          <div style={{textAlign: 'right'}}>
            <CircularProgress size={25} style={{visibility:this.state.submitInProgress ? 'visible' : 'hidden'}}/>
            &nbsp;&nbsp;
            <StandardButton variant="contained" onClick={this.onConfirm} >
              Confirm
            </StandardButton>
          </div>
        </AppForm>
      </Page>
    );
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onConfirm();
    }
  }

  handleInputOnChange = (event) => {

    const updatedFieldName = event.target.name;
    const updatedValue = event.target.value;

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    this.setState(state => ({
      [updatedFieldName]: updatedValue
    }))
  }

  onConfirm = () => {

    let dataModel = {
      username: this.state.username,
      password: this.state.password,
      code: this.state.code
    };

    var atLeastOneError = false;
    // Validation

    if (!dataModel.code) {
      this.setState({codeError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({codeError: ''});
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({submitInProgress: true});
  
    Auth.confirmSignUp(dataModel.username, dataModel.code)
      .then(response => {
        this.setState({
          // as we're going to submit SignIn request hence keep the spinner on
          //submitInProgress: false,
          codeError: ""
        });
        localStorage.setItem(Constants.LOCAL_STORAGE_KEY_IS_NEWLY_JOINED, true);
        sessionStorage.removeItem(Constants.SESSION_STORAGE_KEY_USER_PROFILE_DATA_WITH_TIMESTAMP);
        if (dataModel.password) {
          this.signIn(dataModel.username, dataModel.password);
        } else {
          this.props.history.push('/signIn');
        }
      })
      .catch(err => {
        console.log("Error", err);
        if (err.code == "NetworkError") {
          this.setState({codeError: 'Network error'});
        } else if (err.code == "CodeMismatchException") {
          this.setState({codeError: err.message});
        } else if (err.code == "LimitExceededException") {
          // display error at the last field
          this.setState({codeError: Constants.MESSAGE_RATE_LIMIT_EXCEEDED});
        } else {
          // display error at the last field
          this.setState({codeError: Constants.MESSAGE_SYSTEM_ERROR});
        }

        this.setState({
          submitInProgress: false
        });
      });
  }

  onResendCode = () => {

    let dataModel = {
      username: this.state.username
    };

    this.setState({submitInProgress: true});

    Auth.resendSignUp(dataModel.username)
      .then(response => {
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          submitInProgress: false,
          codeError: ""
        });
      })
      .catch(err => {
        // Use codeError instead of emailError as it doesn't exist
        if (err.code == "NetworkError") {
          this.setState({codeError: 'Network error'});
        } else if (err.code == "LimitExceededException") {
          // display error at the last field
          this.setState({codeError: Constants.MESSAGE_SYSTEM_ERROR});
        } else {
          // display error at the last field
          this.setState({codeError: Constants.MESSAGE_SYSTEM_ERROR});
        }

        this.setState({
          submitInProgress: false
        });
      });
  }

  signIn = (email, password) => {
    Auth.signIn(email, password)
      .then(response => {
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          submitInProgress: false
        });
        this.props.history.push('/editProfile');
      })
      .catch(err => {
        console.log("Error", err);
        // should not happen but just in case,
        // e.g. Post Confirmation Lambda executed asynchronously(?) and not yet completed
        this.props.history.push('/signIn');

        this.setState({
          submitInProgress: false
        });
      });
  }

}

export default SignUpVerifyCode;
