import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Markdown from './modules/components/Markdown';
import Typography from './modules/components/Typography';
import privacy from './modules/views/privacy.md';
import Page from '../components/Page'

class Privacy extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
       privacy: null
    }
  }

  componentDidMount() {
    fetch(privacy).then((response) => response.text()).then((text) => {
      this.setState({ privacy: text })
    })
  }

  render() {

    if (this.state.privacy == null) {
      return null;
    }

    return (
      <Page history={this.props.history} isVerticalCenter={false}>
        <Container>
          <Box mt={7} mb={12}>
            <Typography variant="h4" gutterBottom marked="center" align="center">
              Privacy Policy
            </Typography>
            <Markdown>{this.state.privacy}</Markdown>
          </Box>
        </Container>
      </Page>
    );
  }
}

export default Privacy;
