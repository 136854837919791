import React from 'react';
import Link from '@material-ui/core/Link';

export const HS_PROFILE_PROTECTED_API_URL = window.config.apiBaseUrl + "/hairstylist/profile";
export const HS_PROFILE_PUBLIC_API_BASE_URL = window.config.apiBaseUrl + "/public/hairstylist/profile";
export const HS_REVIEW_API_URL = window.config.apiBaesUrl + "/public/hairstylist/profile/review";
export const HS_SEARCH_API_URL = window.config.apiBaseUrl + "/public/hairstylist/profile";
export const HS_MESSAGE_API_URL = window.config.apiBaseUrl + "/public/hairstylist/message";
export const HS_IMAGES_BASE_URL = window.config.imagesBaseUrl;

export const AWS_REGION = "ap-southeast-2";
export const AWS_USER_POOLS_ID = AWS_REGION + "_" + window.config.v1;
export const AWS_USER_POOLS_WEB_CLIENT_ID = window.config.v2;

export const LOCAL_STORAGE_KEY_IS_NEWLY_JOINED = "isNewlyJoined";
export const SESSION_STORAGE_KEY_USER_PROFILE_DATA_WITH_TIMESTAMP = "userProfileDataWithTimestamp";

export const MESSAGE_RATE_LIMIT_EXCEEDED = "System busy - please try again later";
export const MESSAGE_SYSTEM_ERROR =
        <span>
            System error - please try again.
            If error persists, <Link color="inherit" href="mailto:info@hairstylistsconnect.com" underline="always">contact us</Link>
        </span>;

