import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from './modules/components/Typography';
import AppForm from './modules/views/AppForm';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import isEmail from 'validator/lib/isEmail';
import StandardButton from './modules/components/StandardButton';
import Page from '../components/Page'
import * as Constants from '../functions/Constants';

const MISSING_FIELD_ERROR_MSG = "Required";

class SignIn extends React.Component {

  constructor(props) {    
    super(props);

    this.state = {
      email: "",
      password: "",

      emailError: "",
      passwordError: "",
      submitInProgress: false,
    };
  }

  render() {

    return (
      <Page history={this.props.history} isVerticalCenter={true}>
        <AppForm>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Sign In
          </Typography>
          <Typography variant="body2" align="center">
            {'Not a member yet? '}
            <Link component="button" onClick={() => this.props.history.push('/signUp')} align="center" underline="always">
              Sign up here
            </Link>
          </Typography>
          <br/>
          <br/>          
          <FormControl error={this.state.emailError ? true : false} fullWidth>
                <FormLabel htmlFor="email" required>Email</FormLabel>
                <TextField
                    margin="normal"
                    name="email"
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.handleInputOnChange}
                    error={this.state.emailError ? true : false}                                                                          
                    inputProps={{
                      maxLength: 100
                    }}
                    onKeyDown={this.onKeyDown}
                />
                {
                  this.state.emailError &&
                  <FormHelperText>{this.state.emailError}</FormHelperText>
                }                                   
          </FormControl>              
          <br/>
          <br/> 
          <FormControl error={this.state.passwordError ? true : false} fullWidth>
            <FormLabel htmlFor="password" required>Password</FormLabel>
            <TextField
                margin="normal"
                name="password"
                variant="outlined"
                value={this.state.password}
                onChange={this.handleInputOnChange}
                error={this.state.passwordError ? true : false}                                                                          
                inputProps={{
                  maxLength: 100,
                  type: 'password'
                }}
                onKeyDown={this.onKeyDown}
            />
            {
              this.state.passwordError &&
              <FormHelperText>{this.state.passwordError}</FormHelperText>            
            }                
          </FormControl> 
          <Typography variant="body2" style={{marginTop:'10px'}}>
            <Link component="button" onClick={() => this.props.history.push('/forgotPassword')} underline="always">
              Forgot password?
            </Link>
          </Typography>
          <br/>
          <div style={{textAlign: 'right'}}>
            <CircularProgress size={25} style={{visibility:this.state.submitInProgress ? 'visible' : 'hidden'}}/>
            &nbsp;&nbsp;
            <StandardButton variant="contained" onClick={this.onSignIn}>
              Sign In
            </StandardButton>
          </div>
        </AppForm>
      </Page>
    );
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onSignIn();
    }
  }

  handleInputOnChange = (event) => {

    const updatedFieldName = event.target.name;
    const updatedValue = event.target.value;

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    this.setState(state => ({
      [updatedFieldName]: updatedValue
    }))
  }

  onSignIn = () => {

    let dataModel = {
      email: this.state.email,
      password: this.state.password
    };

    var atLeastOneError = false;
    // Validation

    if (!dataModel.email) {
      this.setState({emailError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (!isEmail(dataModel.email.trim())) {
      this.setState({emailError: "Invalid email"});
      atLeastOneError = true;      
    } else {
      this.setState({emailError: ''});
    }

    if (!dataModel.password) {
      this.setState({passwordError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({passwordError: ''});
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({submitInProgress: true});
  
    Auth.signIn(dataModel.email, dataModel.password)
      .then(response => {
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          submitInProgress: false,
          emailError: "",
          passwordError: ""    
        });
        if (this.props.location.state && this.props.location.state.callbackUrl) {
          this.props.history.replace(this.props.location.state.callbackUrl);
        } else {
          this.props.history.push('/editProfile');
        }
      })
      .catch(err => {
        console.log("Error", err);
        if (err.code == "NetworkError") {
          this.setState({emailError: 'Network error'});
        } else if (err.code == "UserNotConfirmedException") {
          this.setState({emailError:
            <>
              <span>Email address has not been confirmed.</span>&nbsp;&nbsp;
              <Link
                component="button"
                color="inherit"
                onClick={() => this.props.history.push('/signUpVerifyCode', {username: this.state.email})}
                underline="always"
              >
                Confirm
              </Link>
            </>
          });
        } else if (err.code == "UserNotFoundException" || err.code == 'NotAuthorizedException') {
          this.setState({emailError: 'Incorrect username or password.'});
        } else if (err.code == "LimitExceededException") {
          // display error at the last field
          this.setState({passwordError: Constants.MESSAGE_RATE_LIMIT_EXCEEDED});
        } else {
          // display error at the last field
          this.setState({passwordError: Constants.MESSAGE_SYSTEM_ERROR});
        }


        this.setState({
          submitInProgress: false
        });
      });
  }
}

export default SignIn;
