import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Rating from '@material-ui/lab/Rating';
import { transformToViewModel } from './ViewProfile';
import * as MiscFunctions from '../functions/MiscFunctions';
import Typography from '../onepirate/modules/components/Typography';
import Page from '../components/Page'
import PersonIcon from '@material-ui/icons/Person';
import * as Constants from '../functions/Constants';
import { Auth } from 'aws-amplify';
import Search from './Search';

class SearchByPublic extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
        <Page history={this.props.history} isVerticalCenter={false}>
          <Search
            history={this.props.history}
            viewedByAnyHairStylist={false}
          />
        </Page>
      )
    }
}

SearchByPublic.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SearchByPublic;
