import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import ResponsiveDrawer from '../../../pages/ResponsiveDrawer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({

  placeholder: toolbarStyles(theme).root,
  rightLink: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.common.white,
    float:'right'
  },
  appBar: {
    height: '65px',
    zIndex: 900
    // Default is 1000, set a smaller value than that of LightBox default of 1000 such that
    // the lightbox buttons could be shown. Incresing LightBox zIndex isn't effective
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white'
  }
});

  function AppAppBar(props) {
  const { classes, history, showMenu, showBackButton } = props;

  var { showSignInLink } = props;
  if (typeof(showSignInLink) === 'undefined' ) {
    showSignInLink = true; // show sign in link by default
  }

  return (
    <div>

      <AppBar position="fixed" color="secondary" className={classes.appBar}>
{/*      <AppBar position="fixed"  style={{backgroundImage:'linear-gradient(to right top,#3ec0b8,#56c9c1,#6ad2ca,#7ddbd4,#8fe4dd)'}}>*/}
        <Toolbar>
          {
            showMenu &&
            <ResponsiveDrawer currentPageText="Edit Profile" history={history}/>
          }
          {
            showBackButton &&
            <IconButton
              aria-label="Back"
              edge="start"
              onClick={() => history.goBack()}
              className={classes.menuButton}
            >
              <ArrowBackIcon />
            </IconButton>
          }
          <div style={{width:'100%'}}>
            <Link
              underline="none"
              component="button"
              onClick={() => history.push('/')}            
            >
              <img src="/static/logo.png" height="53px"
                style={{position: 'fixed', left: '50%', top: 7, transform: 'translate(-50%)'}}/>
            </Link>              
            {
              !showMenu && !showBackButton && showSignInLink &&
              <Link
              //variant="h6"
              underline="none"
              className={classes.rightLink}
              component="button"              
              onClick={() => history.push('/signIn')}
              >
                {'Sign in'}
              </Link>
            }
            </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
