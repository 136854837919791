import React from 'react';
import { Switch, Route } from 'react-router-dom'

import ProtectedApp from './ProtectedApp';
import OldProtectedApp from './OldProtectedApp'
import SearchByPublic from './pages/SearchByPublic'
import ViewProfileViaSearchResults from './pages/ViewProfileViaSearchResults'
import ViewProfileViaDirectLink from './pages/ViewProfileViaDirectLink'
import Home from './onepirate/Home'
import SignIn from './onepirate/SignIn'
import SignUp from './onepirate/SignUp'
import SignUpVerifyCode from './onepirate/SignUpVerifyCode'
import ForgotPassword from './onepirate/ForgotPassword'
import ResetPassword from './onepirate/ResetPassword'
import Terms from './onepirate/Terms'
import Privacy from './onepirate/Privacy'
import Helmet from 'react-helmet'

function RouteWithTitle({ title, description, ...props }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {/*<meta name="description" content={description} />*/}
      </Helmet>
      <Route {...props} />
    </>
  )
}

class App extends React.Component {

  render() {
    return (
      <Switch>
          <Route exact path='/' component={Home}/>
          <RouteWithTitle title="Search Hair Stylists" exact path='/search' component={SearchByPublic}/>
          <RouteWithTitle title="Find Hair Stylists Melbourne" exact path='/search/melbourne'
            render={(props) => (
              <SearchByPublic {...props} region="Melbourne" />
            )}/>
          <Route exact path='/search/:profileId' component={ViewProfileViaSearchResults}/>               
          <Route exact path={['/editProfile', '/viewProfile', '/searchByStylist', '/changePassword']} component={ProtectedApp}/>
          <Route exact path='/signIn' component={SignIn}/>
          <Route exact path='/signUp' component={SignUp}/>
          <Route exact path='/signUpVerifyCode' component={SignUpVerifyCode}/>
          <Route exact path='/forgotPassword' component={ForgotPassword}/>
          <Route exact path='/resetPassword' component={ResetPassword}/>               
          <Route exact path='/terms' component={Terms}/>                              
          <Route exact path='/privacy' component={Privacy}/>                                             
          <Route exact path={['/test']} component={OldProtectedApp}/>               
          <Route exact path='/:profileId' component={ViewProfileViaDirectLink}/>               
      </Switch>
    );
  }
}

export default App;
