import React from 'react';
import PropTypes from 'prop-types';
import MuiAppBar from '@material-ui/core/AppBar';

function AppBar(props) {
  return <MuiAppBar elevation={0} {...props} />;
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AppBar;
