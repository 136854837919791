import React from 'react';
// eslint-disable-next-line
import { Switch, Route } from 'react-router-dom'
import EditProfile from './pages/EditProfile';

import { Authenticator, ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports'; // if you are using Amplify CLI
import ViewProfileByHairStylist from './pages/ViewProfileByHairStylist';

Amplify.configure(awsconfig);

class OldProtectedApp extends React.Component {

  render() {
    return (
        <main>
            <Switch>
               <Route exact path='/editProfile' component={EditProfile}/>               
               <Route path='/viewProfile' component={ViewProfileByHairStylist}/>
               <Route path='/changePassword' component={ViewProfileByHairStylist}/>               
           </Switch>
        </main>
    );
  }
}

class MySignUp extends React.Component {

  constructor(props) {
    super(props);
    this._validAuthStates = ["signUp", "signIn"];
  }

  showComponent(theme) {
    return (
      <div>HELLO</div>
    )
  }
}

export default withAuthenticator(OldProtectedApp, {
    authenticatorComponents:[
      <SignIn/>,
      <ConfirmSignIn/>,
      <VerifyContact/>,
      <SignUp/>,
      <ConfirmSignUp/>,
      <ForgotPassword/>,
      <RequireNewPassword />      
    ],
    /*
    signUpConfig: {
//      hiddenDefaults: ["phone_number"],
      signUpFields: [
        { label: "Fist Name", key: "given_name", required: true, type: "string" , displayOrder: 5},
        { label: "Last Name", key: "family_name", required: true, type: "string" , displayOrder: 6},
        { label: "Gender", key: "gender", required: true, type: "gender", displayOrder: 7},        
        { label: "Date of Birth", key: "birthdate", required: true, type: "date", displayOrder: 8},                
        { label: "Profile", key: "profile", required: true, type: "string" , displayOrder: 9},
    ],
      defaultCountryCode: 61
}
*/
});