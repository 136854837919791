import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Rating from '@material-ui/lab/Rating';
import { transformToViewModel } from './ViewProfile';
import * as MiscFunctions from '../functions/MiscFunctions';
import Typography from '../onepirate/modules/components/Typography';
import Page from '../components/Page'
import PersonIcon from '@material-ui/icons/Person';
import * as Constants from '../functions/Constants';
import { Auth } from 'aws-amplify';
import Search from './Search';

class SearchByHairStylist extends React.Component {

  constructor(props) {
    super(props);
 
    this.state = {
      isLoaded: false,
      userProfileViewModel: null
    };
  } 

  componentDidMount() {
    Auth.currentSession({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(session => {
      
        console.log(session);
        fetch(
          Constants.HS_PROFILE_PROTECTED_API_URL,
          {
            headers: new Headers(
            {
            'Authorization': session.getIdToken().getJwtToken()
            })
          }
        )
        .then(response => response.json())
        .then(dataModel => {
          console.log("dataModel: " + JSON.stringify(dataModel));
          this.setState({userProfileViewModel: transformToViewModel(dataModel), isLoaded: true});
        })
        .catch(err => console.log(err));
      }
    )
    .catch(err => console.log(err));
  }

  render() {

    if (!this.state.isLoaded) {
      return MiscFunctions.DISPLAY_SPINNER_IN_MIDDLE;
    } else {
        return (
            <Page history={this.props.history} showMenu={true} isVerticalCenter={false}>
              <Search
                  history={this.props.history}
                  viewerId={this.state.userProfileViewModel.id}
              />
            </Page>
        )
    }
  }
}

SearchByHairStylist.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SearchByHairStylist;
