import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SecurityIcon from '@material-ui/icons/Security';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '../onepirate/modules/components/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    backgroundColor: 'transparent',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white'
    /*
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    */
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { container, currentPageText, history } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const menu = [
      {
          text: "Edit Profile",
          uri: "/editProfile",
          icon: <PersonIcon/>,
          divider: false
      },
      {
          text: "Public View",
          uri: "/viewProfile",
          icon: <PublicIcon/>,
          divider: true          
      },
      {
        text: "Search",
        uri: "/searchByStylist",
        icon: <SearchIcon/>,
        divider: false          
      },
      {
        text: "Change Password",
        uri: "/changePassword",
        icon: <SecurityIcon/>,      
        divider: false        
      },
      {
          text: "Logout",
          icon: <ExitToAppIcon/>,
          divider: false,
          callback: logout          
      }
  ];

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
          {
            menu.map((menuItem, index) => (
          <React.Fragment key={menuItem.text}>
          <ListItem button onClick={() => menuItem.callback ? menuItem.callback(history) : history.push(menuItem.uri)}>
             <ListItemIcon>{menuItem.icon}</ListItemIcon>
             <ListItemText primary={menuItem.text} />
          </ListItem>
          {menuItem.divider ? <Divider /> : ""}
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>

          <IconButton
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="inherit" noWrap color="secondary">
            {currentPageText}
          </Typography>
          {/*
          <div style={{textAlign: 'right'}}>
            <div style={{display:'inline'}}>
                Edit Profile
            </div>
            <div style={{display:'inline'}}>
                Public Profile View
            </div>
            <div style={{display:'inline'}}>
                Logout
            </div>
          </div>
          */}


        {/*
        <AppBar>
        <Toolbar>
            <Menu autoWidth={false} open={true}>
                        <MenuItem primaryText="Maps"  style={styleq}>Edit Profile</MenuItem>
                        <MenuItem primaryText="Books"  style={styleq}>Public Profile View</MenuItem>
                        <MenuItem primaryText="Flights" style={styleq}>Logout</MenuItem>
            </Menu>
        </Toolbar>
        </AppBar>
        */}

      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/*
        <Hidden smUp implementation="css">
        */}
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        {/*
        </Hidden>
        */}        
{/*        
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="persistent"
            open
            anchor="left"
          >
            {drawer}
          </Drawer>
        </Hidden>
        */}
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};

function logout(history) {
  Auth.signOut()
  .then(data => {
    console.log(data);
    history.push('/');
  })
  .catch(err => console.log(err));
}

export default ResponsiveDrawer;
