import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import RoundButton from './modules/components/RoundButton';
import Typography from './modules/components/Typography';
import SearchIcon from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider';
import Page from '../components/Page';

const styles = theme => ({
  upperSection: {
    marginTop: theme.spacing(2),
  },
  lowerSection: {
    marginBottom: theme.spacing(5),
  },  
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontFamily: theme.typography.fontFamilySecondary,
    fontWeight: 'normal',
    fontSize: '18px'
  },
  icon: {
    marginBottom: theme.spacing(2),
    fontSize: 35,
    color: theme.palette.secondary.dark,
  },
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%'
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  }
});

class Home extends React.Component {

  constructor(props) {    
    super(props);
  }

  render() {

    const { classes, history } = this.props;

    return (
      <Page history={history} isVerticalCenter={false} skipMain={true} showFooter={true}>
        <main className={classes.main}>
          <section className={classes.upperSection}>
            <Container className={classes.container}>
              <SearchIcon className={classes.icon}/>                        
              <Typography variant="h5" align="center" className={classes.title}>
                        Look for Hair Stylists ?
              </Typography>
              <RoundButton
                      variant="contained"
                      onClick={() => this.props.history.push('/search')}
                    >
                      Search
              </RoundButton>
            </Container>
          </section>

          <div style={{width:'100%', textAlign:'center'}}>
            <div style={{width:'65%', display: 'inline-block'}}>
              <Divider/>
            </div>
          </div>

          <section className={classes.lowerSection}>
            <Container className={classes.container}>
              <PersonAddIcon className={classes.icon}/>                        
              <Typography variant="h5" align="center" className={classes.title}>
                Are you a Hair Stylist ?
              </Typography>

              <RoundButton
                      variant="contained"
                      onClick={() => this.props.history.push('/signUp')}
                    >
                      Sign up for free
              </RoundButton>
                </Container>
          </section>
        </main>
      </Page>
    );
  }
}

export default withStyles(styles)(Home);