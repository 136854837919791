import React from 'react';
import Typography from './modules/components/Typography';
import AppForm from './modules/views/AppForm';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import StandardButton from './modules/components/StandardButton';
import { Auth } from 'aws-amplify';
import ResetPassword from './ResetPassword';
import isEmail from 'validator/lib/isEmail';
import Page from '../components/Page'
import Link from '@material-ui/core/Link';
import * as Constants from '../functions/Constants';

const MISSING_FIELD_ERROR_MSG = "Required";

class ForgotPassword extends React.Component {

  constructor(props) {    
    super(props);

    this.state = {
      email: "",

      emailError: "",
      submitInProgress: false,
      submitSucceeded: false
    };
  }

  render() {

    if (this.state.submitSucceeded) {
      return (<ResetPassword email={this.state.email} history={this.props.history}/>)
    }

    return (
      <Page history={this.props.history} isVerticalCenter={true}>
        <AppForm>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Forgot your password?
          </Typography>
          <Typography variant="body2" align="center">
            {"Enter your email address below and we'll " +
              'send you a code to reset your password.'}
          </Typography>
          <br/>
          <br/>
          <FormControl error={this.state.emailError ? true : false} fullWidth>
                <FormLabel htmlFor="email" required>Email</FormLabel>
                <TextField
                    margin="normal"
                    name="email"
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.handleInputOnChange}
                    error={this.state.emailError ? true : false}                                                                          
                    inputProps={{
                      maxLength: 100
                    }}
                    onKeyDown={this.onKeyDown}
                />
                {
                  this.state.emailError &&
                  <FormHelperText>{this.state.emailError}</FormHelperText>
                }                                   
          </FormControl>              
          <br/>
          <br/>
          <div style={{textAlign: 'right'}}>
            <CircularProgress size={25} style={{visibility:this.state.submitInProgress ? 'visible' : 'hidden'}}/>
            &nbsp;&nbsp;
            <StandardButton variant="contained" onClick={this.onSendCode} >
              Send Code
            </StandardButton>
          </div>
        </AppForm>
      </Page>
    );
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onSendCode();
    }
  }

  handleInputOnChange = (event) => {

    const updatedFieldName = event.target.name;
    const updatedValue = event.target.value;

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    this.setState(state => ({
      [updatedFieldName]: updatedValue
    }))
  }

  onSendCode = () => {

    let dataModel = {
      email: this.state.email
    };

    var atLeastOneError = false;
    // Validation

    if (!dataModel.email) {
      this.setState({emailError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (!isEmail(dataModel.email.trim())) {
      this.setState({emailError: "Invalid email"});
      atLeastOneError = true;      
    } else {
      this.setState({emailError: ''});
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({submitInProgress: true});

    console.log("dataModel: " + JSON.stringify(dataModel));

    Auth.forgotPassword(dataModel.email)
      .then(response => {
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          submitInProgress: false,
          emailError: "",
          submitSucceeded: true
        });
      })
      .catch(err => {
        console.log("Error", err);
        if (err.code == "NetworkError") {
          this.setState({emailError: 'Network error'});
        } else if (err.code == "InvalidParameterException") {
          this.setState({emailError:
            <>
              <span>Email address has not been confirmed.</span>&nbsp;&nbsp;
              <Link
                component="button"
                color="inherit"
                onClick={() => this.props.history.push('/signUpVerifyCode', {username: this.state.email})}
                underline="always">
                Confirm
              </Link>
            </>
          });
        } else if (err.code == "UserNotFoundException") {
          this.setState({emailError: 'Incorrect username'});
        } else if (err.code == "LimitExceededException") {
          // display error at the last field
          this.setState({emailError: Constants.MESSAGE_RATE_LIMIT_EXCEEDED});
        } else {
          // display error at the last field
          this.setState({emailError: Constants.MESSAGE_SYSTEM_ERROR});
        }

        this.setState({
          submitInProgress: false
        });
      });
  }
}

export default ForgotPassword;
