import React from 'react';
import { Auth } from 'aws-amplify';
import ViewProfile from './ViewProfile';
import { transformToViewModel } from './ViewProfile';
import * as MiscFunctions from '../functions/MiscFunctions';
import Page from '../components/Page';
import * as Constants from '../functions/Constants';

class ViewProfileByHairStylist extends React.Component {

  constructor(props) {
    super(props);
 
    this.state = {
      isLoaded: false,
      userProfileViewModel: null
    };
  } 

  componentDidMount() {
    Auth.currentSession({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(session => {
      
      console.log(session);

        fetch(
          Constants.HS_PROFILE_PROTECTED_API_URL,
          {
            headers: new Headers(
            {
            'Authorization': session.getIdToken().getJwtToken()
            })
          }
        )
        .then(response => response.json())
        .then(dataModel => {
          console.log("dataModel: " + JSON.stringify(dataModel));
          this.setState({userProfileViewModel: transformToViewModel(dataModel), isLoaded: true});
        })
        .catch(err => console.log(err));
      }
    )
    .catch(err => console.log(err));
  }

  render() {

    if (!this.state.isLoaded) {
      return MiscFunctions.DISPLAY_SPINNER_IN_MIDDLE;
    } else {
        return (
            <Page history={this.props.history} showMenu={true}>
              <ViewProfile
                  userProfileViewModel={this.state.userProfileViewModel}
                  history={this.props.history}
              />
            </Page>
        )
    }
  }
}

ViewProfileByHairStylist.propTypes = {
};

export default ViewProfileByHairStylist;
