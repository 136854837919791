import React from 'react';
import ViewProfile from './ViewProfile';
import { transformToViewModel } from './ViewProfile';
import * as MiscFunctions from '../functions/MiscFunctions';
import Page from '../components/Page'
import * as Constants from '../functions/Constants';

class ViewProfileViaSearchResults extends React.Component {

  constructor(props) {
    super(props);

    console.log('props', JSON.stringify(props));
    console.log('props.location.state', props.location.state);

    var isLoaded = false;
    var userProfileViewModel = null;
    var underSearchContext = false;

    if (props.location.state) {
      userProfileViewModel = props.location.state.profile;
      isLoaded = true;
      underSearchContext = true;
    }

    this.state = {
      isLoaded: isLoaded,
      userProfileViewModel: userProfileViewModel,
      profileId: props.match.params.profileId,
      underSearchContext: underSearchContext,
      history: props.history,
      viewerId: props.location.state.viewerId
    };

    console.log("this.state", this.state);
  }

  componentDidMount() {

      if (!this.state.isLoaded) {
        fetch(
          Constants.HS_PROFILE_PUBLIC_API_BASE_URL + "/" + this.state.profileId
        )
        .then(response => {
          console.log("statusCode: " + response.status);
          if (response.status === 200) {
            return response.json();
          } else {
            this.state.history.replace("/search");
            return null;
          }
        })
        .then(dataModel => {
          if (dataModel != null) {
            console.log("dataModel: " + JSON.stringify(dataModel));
            this.setState({userProfileViewModel: transformToViewModel(dataModel), isLoaded: true});
          }
        })
        .catch(err => console.log(err));
      }
  }
  render() {

    if (!this.state.isLoaded) {
      return MiscFunctions.DISPLAY_SPINNER_IN_MIDDLE;
    } else {
        return (
          <Page history={this.props.history} showBackButton={true}>
            <ViewProfile
                userProfileViewModel={this.state.userProfileViewModel}
                history={this.props.history}
                underSearchContext={this.state.underSearchContext}
                viewerId={this.state.viewerId}
            />
          </Page>
        )
    }
  }
}

ViewProfileViaSearchResults.propTypes = {
};

export default ViewProfileViaSearchResults;
