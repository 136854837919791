import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from './modules/components/Typography';
import AppForm from './modules/views/AppForm';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import isEmail from 'validator/lib/isEmail';
import StandardButton from './modules/components/StandardButton';
import Page from '../components/Page'
import PortraitIcon from '@material-ui/icons/Portrait';
import PeopleIcon from '@material-ui/icons/People';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { withStyles } from '@material-ui/core/styles';
import SignUpVerifyCode from './SignUpVerifyCode';
import * as Constants from '../functions/Constants';

const MISSING_FIELD_ERROR_MSG = "Required";

const styles = theme => ({
  icon: {
    fontSize: theme.typography.subtitle1.fontSize,
    verticalAlign: '-4px',
    color: theme.palette.secondary.dark
  }
});

class SignUp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      gender: null,
      password: "",
      confirmPassword: "",

      firstNameError: "",
      lastNameError: "",
      emailError: "",
      genderError: "",
      passwordError: "",
      confirmPasswordError: "",

      submitInProgress: false,
      submitSucceeded: false,
    };
  }

  render() {

    const { classes } = this.props;

    if (this.state.submitSucceeded) {
      return (<SignUpVerifyCode username={this.state.email} password={this.state.password} history={this.props.history}/>)
    }

    return (
      <Page history={this.props.history} isVerticalCenter={true}>
        <AppForm>
          <Typography variant="h4" gutterBottom marked="center" align="center">
            Sign Up
          </Typography>
            <React.Fragment>
              <Typography variant="subtitle1" align="center" style={{paddingTop:'10px'}}>
                <span>Create your hair stylist profile</span>
              </Typography>
              <div style={{display:'flex', justifyContent:'center', paddingTop:'10px'}}>
                <Typography variant="body1">
                  <div><PeopleIcon className={classes.icon}/>&nbsp;&nbsp;Reach potential customers</div>
                  <div><PortraitIcon className={classes.icon}/>&nbsp;&nbsp;Showcase your portfolios</div>
                  <div><ContactMailIcon className={classes.icon}/>&nbsp;&nbsp;Connect with other stylists</div>
                  <div><MoneyOffIcon className={classes.icon}/>&nbsp;&nbsp;It's FREE</div>
                </Typography>
              </div>
              <br/>
              <br/>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl error={this.state.firstNameError ? true : false} fullWidth>
                    <FormLabel htmlFor="firstName" required>First name</FormLabel>
                    <TextField
                        margin="normal"
                        name="firstName"                      
                        variant="outlined"
                        autoComplete="fname"
                        value={this.state.firstName}
                        onChange={this.handleInputOnChange}
                        error={this.state.firstNameError ? true : false}                                                                          
                        inputProps={{
                          maxLength: 100
                        }}
                        onKeyDown={this.onKeyDown}
                    />
                    {
                      this.state.firstNameError &&
                      <FormHelperText>{this.state.firstNameError}</FormHelperText>
                    }
                  </FormControl>                
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl error={this.state.lastNameError ? true : false} fullWidth>
                    <FormLabel htmlFor="lastName" required>Last name</FormLabel>
                    <TextField
                        margin="normal"
                        name="lastName"                      
                        variant="outlined"
                        autoComplete="lname"
                        value={this.state.lastName}
                        onChange={this.handleInputOnChange}
                        error={this.state.lastNameError ? true : false}                                                                          
                        inputProps={{
                          maxLength: 100
                        }}
                        onKeyDown={this.onKeyDown}
                    />
                  {
                    this.state.lastNameError &&
                    <FormHelperText>{this.state.lastNameError}</FormHelperText>
                  }                    
                </FormControl>    
                </Grid>
              </Grid>
              <br/>
              <br/>             
              <FormControl error={this.state.emailError ? true : false} fullWidth>
                <FormLabel htmlFor="email" required>Email</FormLabel>
                <TextField
                    margin="normal"
                    name="email"
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.handleInputOnChange}
                    error={this.state.emailError ? true : false}                                                                          
                    inputProps={{
                      maxLength: 100
                    }}
                    onKeyDown={this.onKeyDown}
                />
                {
                  this.state.emailError &&
                  <FormHelperText>{this.state.emailError}</FormHelperText>
                }                                   
              </FormControl>              
              <br/>
              <br/>
            {/*
            <FormControl fullWidth error={this.state.genderError ? true : false} component="fieldset">
              <FormLabel component="legend" required>Gender</FormLabel>
              <RadioGroup
                name="gender"
                value={this.state.gender}              
                onChange={this.handleInputOnChange}
                row
              >
                <FormControlLabel value="Male" control={<Radio color="default"/>} label="Male" />
                <FormControlLabel value="Female" control={<Radio color="default"/>} label="Female" />
              </RadioGroup>
              {
                this.state.genderError &&
                <FormHelperText>{this.state.genderError}</FormHelperText>            
              }
            </FormControl>
              <br/>
              <br/>
            */}
              <FormControl error={this.state.passwordError ? true : false} fullWidth>
                <FormLabel htmlFor="password" required>Password</FormLabel>
                <TextField
                    margin="normal"
                    name="password"
                    variant="outlined"
                    value={this.state.password}
                    onChange={this.handleInputOnChange}
                    error={this.state.passwordError ? true : false}                                                                          
                    inputProps={{
                      maxLength: 100,
                      type: 'password'
                    }}
                    onKeyDown={this.onKeyDown}
                />
                {
                  this.state.passwordError &&
                  <FormHelperText>{this.state.passwordError}</FormHelperText>            
                }                
              </FormControl> 
              <br/>
              <br/>
              <FormControl error={this.state.confirmPasswordError ? true : false} fullWidth>
                <FormLabel htmlFor="confirmPassword" required>Confirm Password</FormLabel>
                <TextField
                    margin="normal"
                    name="confirmPassword"
                    variant="outlined"
                    value={this.state.confirmPassword}
                    onChange={this.handleInputOnChange}
                    error={this.state.confirmPasswordError ? true : false}                                                                          
                    inputProps={{
                      maxLength: 100,
                      type: 'password'
                    }}
                    onKeyDown={this.onKeyDown}
                />
                {
                  this.state.confirmPasswordError &&
                  <FormHelperText>{this.state.confirmPasswordError}</FormHelperText>            
                }                
              </FormControl> 
              <br/>
              <br/>

              <Typography variant="body2">
                {'By signing up I accept the '}
                <Link component="a" onClick={() => this.props.history.push('/terms')} align="center" underline="always">
                  Terms and Conditions
                </Link>
                {' and '}
                <Link component="a" onClick={() => this.props.history.push('/privacy')} align="center" underline="always">
                  Privacy Policy
                </Link>
              </Typography>
              <br/>
              <div style={{textAlign: 'right'}}>
                <CircularProgress size={25} style={{visibility:this.state.submitInProgress ? 'visible' : 'hidden'}}/>
                &nbsp;&nbsp;
                <StandardButton variant="contained" onClick={this.onSignUp} >
                  Sign Up
                </StandardButton>
              </div>
            </React.Fragment>
        </AppForm>
      </Page>
    );
  }

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onSignUp();
    }
  }

  handleInputOnChange = (event) => {

    const updatedFieldName = event.target.name;
    const updatedValue = event.target.value;

    // Chrome doesn't enforce maxLength for input fields with type=number hence we do it ourselves
    if (event.target.type === "number" && event.target.maxLength && event.target.maxLength !== -1) {
      if (updatedValue.length > event.target.maxLength) {
        return;
      }      
    }

    this.setState(state => ({
      [updatedFieldName]: updatedValue
    }))
  }

  onSignUp = () => {

    let dataModel = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      gender: this.state.gender,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };

    var atLeastOneError = false;
    // Validation
    if (!dataModel.firstName) {
      this.setState({firstNameError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({firstNameError: ''});
    }

    if (!dataModel.lastName) {
      this.setState({lastNameError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({lastNameError: ''});
    }

    if (!dataModel.email) {
      this.setState({emailError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (!isEmail(dataModel.email.trim())) {
      this.setState({emailError: "Invalid email"});
      atLeastOneError = true;      
    } else {
      this.setState({emailError: ''});
    }
/*
    if (dataModel.gender == null) {
      this.setState({genderError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else {
      this.setState({genderError: ''});
    }
*/
    if (!dataModel.password) {
      this.setState({passwordError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (dataModel.password.length < 8) {
      this.setState({passwordError: "Password must contain at least 8 characters"});
      atLeastOneError = true;      
    } else {
      this.setState({passwordError: ''});
    }

    if (!dataModel.confirmPassword) {
      this.setState({confirmPasswordError: MISSING_FIELD_ERROR_MSG});
      atLeastOneError = true;      
    } else if (dataModel.confirmPassword != dataModel.password) {
      this.setState({confirmPasswordError: "Passwords do not match"});
      atLeastOneError = true;
    } else {
      this.setState({confirmPasswordError: ''});
    }

    if (atLeastOneError) {
      return;
    }

    this.setState({submitInProgress: true});
  
    Auth.signUp({
      username: dataModel.email,
      password: dataModel.password,
      attributes: {
          email: dataModel.email,
          "custom:custom:family_name": dataModel.lastName,
          "custom:custom:given_name": dataModel.firstName
          //gender: dataModel.gender
      },
      validationData: []  //optional
      })
      .then(response => {
        this.setState({
          // disable all the spinners as we don't know which one triggers this
          submitInProgress: false,
          firstNameError: "",
          lastNameError: "",
          emailError: "",
          genderError: "",
          passwordError: "",
          confirmPasswordError: "",
          submitSucceeded: true
        });
      })
      .catch(err => {
        console.log("Error", err);
        if (err.code == "NetworkError") {
          this.setState({emailError: 'Network error'});
        } else if (err.code == "UsernameExistsException") {
          this.setState({emailError: 'An account with this email address already exists.'});
        } else if (err.code == "InvalidPasswordException") {
          this.setState({passwordError: err.message});
        } else if (err.code == "LimitExceededException") {
          // display error at the last field
          this.setState({confirmPasswordError: Constants.MESSAGE_RATE_LIMIT_EXCEEDED});
        } else {
          // display error at the last field
          this.setState({confirmPasswordError: Constants.MESSAGE_SYSTEM_ERROR});
        }
        this.setState({
          submitInProgress: false
        });
      });
  }  
}

export default withStyles(styles)(SignUp);


