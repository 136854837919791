import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Portfolio from './Portfolio';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';

import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ReviewDialog from './ReviewDialog';
import ContactDialog from './ContactDialog'
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';

import Rating from '@material-ui/lab/Rating';
import { getImageUrl } from '../functions/ImageFunctions';
import * as ArrayFunctions from '../functions/ArrayFunctions';
import StandardButton from '../onepirate/modules/components/StandardButton';
import Typography from '../onepirate/modules/components/Typography';
import PersonIcon from '@material-ui/icons/Person';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  centre: {
    display:'flex',
    justifyContent:'space-around'
  },
  profileDataBlock: {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
      width: '100%',
      verticalAlign: 'top',
      textAlign: 'center'  
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
      width: '50%',
      verticalAlign: 'top',
      textAlign: 'center'  
    }
  },
  centerCenter: {
    display:'flex', justifyContent:'center'
  },
  table: {
    maxWidth: 500,
  },
  headerRow: {
      backgroundColor: `#fafafa`
  },
  headerCell: {
    color: 'rgba(0, 0, 0, 0.5)',
    lineHeight: '1.3em'
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  profileDataSection: {
    [theme.breakpoints.up('md')]: {
      width: '80%',
      margin: 'auto'  
    }
  },
  subSectionDivider: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      marginBottom: '20px'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '20px',
      marginBottom: '20px',
      display: 'none'
    }
  },
  blankProfileDataHorizontalRuler: {
    width: '30px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  } 
});

const coverPictureStyle = {
    width: '100%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '200px'
};

class ViewProfile extends React.Component {

  constructor(props) {
    super(props);
 
    this.state = {
      reviewDialogOpen: false,
      contactDialogOpen: false,
      userProfileViewModel: props.userProfileViewModel,
      expandBiography: false,
      underSearchContext: props.underSearchContext,
      history: props.history,
      viewerId: props.viewerId
    };
  }

  getGridListCols = () => {

    const { width } = this.props;

    if (isWidthUp('xl', width)) {
      return 4;
    }

    if (isWidthUp('lg', width)) {
      return 4;
    }

    if (isWidthUp('md', width)) {
      return 3;
    }

    if (isWidthUp('sm', width)) {
      return 3;
    }

    return 3;
  }

  getGridCellHeight = () => {

    const { width } = this.props;

    if (isWidthUp('xl', width)) {
      return '15vw';
    }

    if (isWidthUp('lg', width)) {
      return '15vw';
    }

    if (isWidthUp('md', width)) {
      return '26.66vw';
    }

    if (isWidthUp('sm', width)) {
      return '32.5vw';
    }

    return '32.5vw';
  }

  render() {

    const { classes } = this.props;
    const { reviewDialogOpen, contactDialogOpen } = this.state;

    return (
      <React.Fragment>

        <CoverPicture coverPicture={this.state.userProfileViewModel.coverPicture}/>
        <ProfilePicture
          profilePicture={this.state.userProfileViewModel.profilePicture}
          coverPicture={this.state.userProfileViewModel.coverPicture}
          classes={classes}
        />

        <div className={classes.centre}>
          <Typography variant="h5">
            {this.state.userProfileViewModel.firstName}&nbsp;{this.state.userProfileViewModel.lastName}
          </Typography>
        </div>

        <br/>

        <div className={classes.centre}>
          <Typography variant="body1">
            {this.state.userProfileViewModel.occupation}
          </Typography>
        </div>

        <br/> 
        <br/> 

       {/*
        <div className={classes.centre}>
          <Paper>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <TableCell align="center" className={classes.headerCell}>Reviews</TableCell>
                  <TableCell align="center" className={classes.headerCell}>Rating</TableCell>
                  <TableCell align="center" className={classes.headerCell}>Regular Customers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key="data">
                    <TableCell align="center" component="th" scope="row">
                    <span style={{verticalAlign: 'top'}}>
                      {this.state.userProfileViewModel.stats.reviewsCount}
                    </span>
                    <br/>
                    &nbsp;
                    </TableCell>
                    <TableCell align="center">
                    <span style={{verticalAlign: 'top'}}>              
                      {this.state.userProfileViewModel.stats.rating == null ? 'N/A' : this.state.userProfileViewModel.stats.rating}                
                    </span>
                    <br/>
                    <span style={{verticalAlign: 'top'}}>
                      {this.state.userProfileViewModel.stats.rating == null ? "" : <Rating value={this.state.userProfileViewModel.stats.rating} precision={0.1} size="small" readOnly/>}&nbsp;
                    </span>
                    </TableCell>
                    <TableCell align="center">
                    <span style={{verticalAlign: 'top'}}>
                      {this.state.userProfileViewModel.stats.regularCustomersCount}
                      </span>
                    <br/>
                    &nbsp;
                    </TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </div>
      
        <br/>
        <br/>
       */}

        <div className={classes.centerCenter}>

          {
          <StandardButton variant="contained" onClick={this.openReviewDialog}>
            Review
          </StandardButton>
          }

          {
            <StandardButton variant="contained" onClick={this.openContactDialog} >
              Message
            </StandardButton>
          }

          {/*
          <StandardButton variant="contained">
            Follow
          </StandardButton>
          */}

        </div>

        <div className={classes.profileDataSection}>

          <Divider variant="middle" className={classes.divider}/>

          <ProfileSection classes={classes} title="About me" hasContent={this.state.userProfileViewModel.biography}>
            {
              this.state.userProfileViewModel.biography &&
              this.state.userProfileViewModel.biography.length <= 150 &&
                <React.Fragment>
                  {this.state.userProfileViewModel.biography}
                </React.Fragment>                              
            }
            {
              this.state.userProfileViewModel.biography &&
              this.state.userProfileViewModel.biography.length > 150 &&
                <React.Fragment>
                  {this.state.userProfileViewModel.biography.substring(0, 150)}
                  <Link color="secondary"
                    style={{display: this.state.expandBiography ? 'none': 'visible'}}
                    onClick={() => {this.setState( state =>({expandBiography: !state.expandBiography}))
                  }}>
                    ...Read more
                  </Link>
                  <Collapse in={this.state.expandBiography} timeout="auto" unmountOnExit>
                    {this.state.userProfileViewModel.biography.substring(150)}
                  </Collapse>
                </React.Fragment>
            }
          </ProfileSection>

          <Divider variant="middle" className={classes.subSectionDivider}/>

          <ProfileSection classes={classes} title="Salon" hasContent={this.state.userProfileViewModel.salon.name}>
            {this.state.userProfileViewModel.salon.name}
            <br/>
            {this.state.userProfileViewModel.salon.phoneNumber}
            <br/>
            <br/>
            {this.state.userProfileViewModel.salon.addressLine}
            <br/>
            {this.state.userProfileViewModel.salon.suburb}
            <br/>
            {this.state.userProfileViewModel.salon.state}&nbsp;{this.state.userProfileViewModel.salon.postcode}
          </ProfileSection>

          <Divider variant="middle" className={classes.divider}/>

          <ProfileSection classes={classes} title="Working Days" hasContent={this.state.userProfileViewModel.workingDays}>
            {this.state.userProfileViewModel.workingDays}
          </ProfileSection>

          <Divider variant="middle" className={classes.subSectionDivider}/>

          <ProfileSection classes={classes} title="Additional Spoken Languages"
            hasContent={this.state.userProfileViewModel.additionalSpokenLanguages.length !== 0}>
            {
              this.state.userProfileViewModel.additionalSpokenLanguages.map((language, index) => (
                <React.Fragment>
                  {language}
                  <br/>
                </React.Fragment>              
              ))
            }
          </ProfileSection>

          <Divider variant="middle" className={classes.divider}/>

          <ProfileSection classes={classes} title="Awards"
            hasContent={this.state.userProfileViewModel.awards.length !== 0}>
            {
              this.state.userProfileViewModel.awards.map((award, index) => (
                <React.Fragment>
                  {award}
                  <br/>              
                </React.Fragment>              
              ))
            }
          </ProfileSection>

          <Divider variant="middle" className={classes.divider}/>

        </div> {/* End of Profile Data */}

        <div className={classes.root}>
          <Portfolio
            portfolio={this.state.userProfileViewModel.portfolio}
            readOnly={true}
          />

          {
            reviewDialogOpen &&
            <ReviewDialog
              onClose={this.onReviewDialogClose}
              profileId={this.state.userProfileViewModel.id}
              firstName={this.state.userProfileViewModel.firstName}
            />
          }

          {
            contactDialogOpen &&
            <ContactDialog
              onClose={this.onContactDialogClose}
              profileId={this.state.userProfileViewModel.id}
              firstName={this.state.userProfileViewModel.firstName}
              viewerId={this.state.viewerId}
            />
          }
        </div>
        <br/>
        <br/>
      </React.Fragment>
    );
  }

  openReviewDialog = () => {
    this.setState({ reviewDialogOpen: true });
  };

  onReviewDialogClose = () => {
    this.setState({ reviewDialogOpen: false });
  };

  openContactDialog = () => {
    this.setState({ contactDialogOpen: true });
  };

  onContactDialogClose = () => {
    this.setState({ contactDialogOpen: false });
  };
}

function CoverPicture(props) {

  const { coverPicture } = props;

  return (
    <div style={{padding: '15px'}}>
      {
        coverPicture &&
        <div style={{backgroundImage: `url(${coverPicture})`,...coverPictureStyle}}/>
      }
    </div>      
  )
}

function ProfilePicture(props) {

  const { classes, profilePicture, coverPicture } = props;

  const hasProfilePictureStyle = {
    width: 100,
    height: 100,
    top: coverPicture ? -50 : 0
  };

  const noProfilePictureStyle = {
    width: 80,
    height: 80,
    top: coverPicture ? -50 : 0,
    backgroundColor: '#D3D3D3'
  };

  return (
    <>
      {
        profilePicture &&
        <>
          <div className={classes.centre}>
            <Avatar alt="Profile Picture" src={profilePicture} style={hasProfilePictureStyle}/>
          </div>
          {
            !coverPicture &&
            <div style={{padding: '15px'}}/>          
          }
        </>
      }
      {
        !profilePicture &&
        <>
          <div className={classes.centre}>
            <Avatar alt="Profile Picture" style={noProfilePictureStyle}>
              <PersonIcon htmlColor="white" style={{fontSize: '54px'}}/>
            </Avatar>
          </div>
          <div style={{padding: '15px'}}/>
        </>
      }
    </>
  )
}

function ProfileSection(props) {

  const { classes, children, title, hasContent } = props;

  return (
    <div className={classes.profileDataBlock}>
      <Typography color="secondary" variant="body1">
        {title}
      </Typography>
      <br/>
      <Typography style={{width: '90%', textAlign: 'center', display: 'inline-block'}} variant="body1">
        {
          hasContent
          &&
          children
        }
        {
          !hasContent &&
          <React.Fragment>
            <hr className={classes.blankProfileDataHorizontalRuler}/>
          </React.Fragment>              
        }
      </Typography>
    </div>
  )
}

ViewProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(ViewProfile));

export function transformToViewModel(dataModel) {

  let emptyUserProfileViewModel = {
    coverPicture: "",
    profilePicture: "",
    emailAddress: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    occupation: "",
    salon: {
      name: "",
      phoneNumber: "",          
      addressLine: "",
      suburb: "",
      postcode: "",
      state: "",
      website: "",
    },
    workingDays: "", // string instead of array for viewModel
    employmentStatus: "",
    biography: "",
    awards: [],
    additionalSpokenLanguages: [],
    stats: {
      reviewsCount: 0,
      rating: null,
      regularCustomersCount: 0
    },
    id: "",
    portfolio: []
  }

  let userProfileViewModel = {...emptyUserProfileViewModel, ...dataModel};

  // need to do again for salon and stats, as it's nested otherwise all attributes
  // in state.userProfile.salon/stats would be overriden or removed if no such attribute in dataModel
  userProfileViewModel = {
      ...userProfileViewModel,
      salon: {...emptyUserProfileViewModel.salon, ...dataModel.salon},
      stats: {...emptyUserProfileViewModel.stats, ...dataModel.stats}
    };

  // convert to a comma-separated list of working days sorted in natural order
  let allWorkingDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  let stylistWorkingDays = allWorkingDays.filter(value => -1 !== userProfileViewModel.workingDays.indexOf(value))
  userProfileViewModel.workingDays = ArrayFunctions.arrayJoin(stylistWorkingDays, ", ");

  if (userProfileViewModel.profilePicture) {
    userProfileViewModel.profilePicture = getImageUrl(userProfileViewModel.id, userProfileViewModel.profilePicture);
  } else {
    userProfileViewModel.profilePicture = ''
  }

  if (userProfileViewModel.coverPicture) {
    userProfileViewModel.coverPicture = getImageUrl(userProfileViewModel.id, userProfileViewModel.coverPicture);
  } else {
    userProfileViewModel.coverPicture = ''
  }

  for (var i = 0; i < userProfileViewModel.portfolio.length; i++) {
    userProfileViewModel.portfolio[i] = {imageUrl: getImageUrl(userProfileViewModel.id, userProfileViewModel.portfolio[i].imageFile)};
  }

  console.log("userProfileViewModel: " + JSON.stringify(userProfileViewModel));

  return userProfileViewModel;
}

