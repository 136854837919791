import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Lightbox from 'react-image-lightbox';

import IconButton from '@material-ui/core/IconButton';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';

import { isWidthUp } from '@material-ui/core/withWidth';
import { handleUploadImage } from '../functions/ImageFunctions';
import { Auth } from 'aws-amplify';
import * as Constants from '../functions/Constants';
import * as MiscFunctions from '../functions/MiscFunctions';
import Typography from '../onepirate/modules/components/Typography';
import TextButton from '../onepirate/modules/components/TextButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const styles = theme => ({

  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  photoInput: {
    display: 'none'
  },
  gridList: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '80vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60vw',
    },
    //  height: 450,
  },  
  blankProfileDataHorizontalRuler: {
    width: '30px'
  }
});

class Portfolio extends React.Component {

  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isPhotoManagerOpen: false,
      portfolio: props.portfolio,
      removePhotoDialogOpen: false,
      displaySpinnerInMiddle: false      
    };
  } 

  render() {

    const { classes, readOnly } = this.props;
    const { photoIndex, isPhotoManagerOpen, removePhotoDialogOpen } = this.state;

    return (
    <>

      {
        this.state.displaySpinnerInMiddle &&
        MiscFunctions.DISPLAY_SPINNER_IN_MIDDLE
      }

      <GridList className={classes.gridList} cols={this.getGridListCols()} component="div">

      <GridListTile key="Subheader-My-Portfolio" cols={this.getGridListCols()} style={{height: '100', textAlign: 'center'}} component="div">
        <ListSubheader component="div">
          <Typography color="secondary" variant="body1">
           <div style={{display: 'flex', justifyContent:'center', alignItems:'baseline'}}> 
            <span>
              My Portfolio
            </span>
            {
            !readOnly &&
              <span>

                <IconButton onClick={() => this.fileUpload.click()}>
                  <AddCircleOutlineIcon color="secondary" />
                </IconButton>
                <input
                  style={{ visibility: 'hidden'}}
                  accept="image/*"
                  className={classes.photoInput}
                  ref={(fileUpload) => {
                    this.fileUpload = fileUpload;
                  }}
                  onClick={(event) => event.target.value=null}
                  onChange={ (event) => handleUploadImage(event, 500, this.handlePhotoOnAdded)}
                  type="file"
                />
            </span>
            }

                      </div>
          </Typography>
        </ListSubheader>
      </GridListTile>

      {/* Separator */}
      <GridListTile
        key="Subheader-Space"
        cols={this.getGridListCols()}
        style={{height: 0, padding: '15px'}}
        component="div"
      />

      {this.state.portfolio.map((photo, index) => (

        <GridListTile key={'photo' + index} component="div" style={{height: this.getGridCellHeight(), maxWidth: 300, maxHeight: 300}}>
          <img src={photo.imageUrl}
           alt="portfolio"
           onClick={() => this.setState({ isPhotoManagerOpen: true, photoIndex: index })}
        />

        </GridListTile>
      ))}

      {
        readOnly && this.state.portfolio.length == 0 &&
        <React.Fragment>
          <hr className={classes.blankProfileDataHorizontalRuler}/>
        </React.Fragment>              
      }

      </GridList>

      {isPhotoManagerOpen && (
        <Lightbox
          mainSrc={this.state.portfolio[photoIndex].imageUrl}
          nextSrc={this.state.portfolio.length === 1 ? undefined : this.state.portfolio[(photoIndex + 1) % this.state.portfolio.length].imageUrl}
          prevSrc={this.state.portfolio.length === 1 ? undefined : this.state.portfolio[(photoIndex + this.state.portfolio.length - 1) % this.state.portfolio.length].imageUrl}
          onCloseRequest={() => this.setState({ isPhotoManagerOpen: false })}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + this.state.portfolio.length - 1) % this.state.portfolio.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % this.state.portfolio.length,
            })
          }
          toolbarButtons={
            !readOnly &&
            [
            <IconButton onClick={this.handleRemovePhotoDialogClickOpen}>
              <DeleteOutlinedIcon className={classes.icon} />
            </IconButton>
          ]}
        />

      )}

      <Dialog
      open={removePhotoDialogOpen}
      onClose={this.handleRemovePhotoDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Delete this photo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton onClick={this.handleRemovePhotoDialogClose} >
          No
        </TextButton>
        <TextButton onClick={() => this.handlePhotoOnDeleted(photoIndex)} >
          Yes
        </TextButton>

      </DialogActions>
      </Dialog>

    </>
    )
  };

  handleRemovePhotoDialogClickOpen = () => {
    this.setState({ removePhotoDialogOpen: true });
  };

  handleRemovePhotoDialogClose = () => {
    this.setState({ removePhotoDialogOpen: false });
  };

  getGridListCols = () => {

    const { width } = this.props;

    if (isWidthUp('xl', width)) {
      return 4;
    }

    if (isWidthUp('lg', width)) {
      return 4;
    }

    if (isWidthUp('md', width)) {
      return 3;
    }

    if (isWidthUp('sm', width)) {
      return 3;
    }

    return 3;
  }

  getGridCellHeight = () => {

    const { width } = this.props;

    if (isWidthUp('xl', width)) {
      return '15vw';
    }

    if (isWidthUp('lg', width)) {
      return '15vw';
    }

    if (isWidthUp('md', width)) {
      return '26.66vw';
    }

    if (isWidthUp('sm', width)) {
      return '32.5vw';
    }

    return '32.5vw';
  }

  handlePhotoOnAdded = (dataUrl) => {

    this.setState(state => ({
      displaySpinnerInMiddle: true
    }));

    var dataModel = {
      newPortfolioPhotoDataUrl: dataUrl
    };

    this.updateProfile(dataModel, (profileId) => {
      this.setState(prevState => ({displaySpinnerInMiddle: false}));
      this.setState(prevState => {
          const photo = {imageUrl: dataUrl};
          const portfolio = [photo].concat(prevState.portfolio);
          return {
            portfolio
          };
      });
    });
  }

  handlePhotoOnDeleted = (index) => {

    this.setState(state => ({
      displaySpinnerInMiddle: true,      
      removePhotoDialogOpen: false,
    }));

    var dataModel = {
      deletedPortfolioPhotoIndex: index
    };

    this.updateProfile(dataModel, (profileId) => {

      this.setState({ displaySpinnerInMiddle: false });

      if (this.state.portfolio.length === 1) {
        this.setState({isPhotoManagerOpen: false});
      }
      else if (index === this.state.portfolio.length - 1) {
        this.setState({photoIndex: 0});
      }
  
      this.setState(prevState => {
        const portfolio = prevState.portfolio.filter((item, j) => index !== j);
        return {
          portfolio
        };
      });
    });
  }

  updateProfile = (dataModel, onSuccess) => {

    console.log("dataModel: " + JSON.stringify(dataModel));

    Auth.currentSession({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(session => {
      
      console.log(session);

      fetch(
        Constants.HS_PROFILE_PROTECTED_API_URL,
        {
          headers: new Headers(
          {
            'Authorization': session.getIdToken().getJwtToken()
          }),
          method: "PUT",
          body: JSON.stringify(dataModel)
        }
      )
      .then(response => {
        console.log("statusCode: " + response.status);
        if (response.status === 200) {
          if (onSuccess) {
            onSuccess(session.getIdToken().payload.sub);
          }
        }
      })
      .catch(err => {
        console.log("Error", err);
      })
      .catch(err => console.log(err));
    })
  }
}

export default withStyles(styles)(withMobileDialog()(Portfolio));
