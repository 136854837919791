import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import GlobalCss from './GlobalCss'

function Copyright(props) {
  return (
  <Typography color="secondary" variant="body2">
      {'© '}
      <Link color="inherit" component="button" onClick={() => props.history.push('/')}>
        Hair Stylists Connect
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    textAlgin: 'center'
  },
  container: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    maxWidth: 500
  },
  list: {
    margin: 0,
    listStyle: 'none',
    paddingLeft: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    fontSize: '12px',
  },
  copyRight: {
    textAlign: 'center',
  },
  footer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  }
}));

export default function AppFooter(props) {
  const classes = useStyles();
  const { history } = props;

  return (
    <footer className={classes.footer} >
      <Typography component="div" className={classes.root}>
        <Container className={classes.container}>
          <Grid container style={{justifyContent:'space-evenly'}}>
            <Grid item>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  <Link component="button" onClick={() => history.push('/terms')}>Terms</Link>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                  <Link component="button" onClick={() => history.push('/privacy')}>Privacy</Link>
                  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;                
                  <Link href="mailto:info@hairstylistsconnect.com">Contact</Link>
                </li>
              </ul>
            </Grid>          
          </Grid>
        </Container>
      </Typography>
      <div className={classes.copyRight}><Copyright history={history}/></div>        
    </footer>
  );
}
