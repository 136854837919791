import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Rating from '@material-ui/lab/Rating';
import { transformToViewModel } from './ViewProfile';
import * as MiscFunctions from '../functions/MiscFunctions';
import Typography from '../onepirate/modules/components/Typography';
import Page from '../components/Page'
import PersonIcon from '@material-ui/icons/Person';
import * as Constants from '../functions/Constants';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  list: {
    flexGrow: 1,
    maxWidth: '600px'
  },
  inline: {
    display: 'inline',
  },
});

class Search extends React.Component {

  constructor(props) {
    super(props);

    console.log("props", JSON.stringify(props));

    var isLoaded = false;
    var userProfileViewModels = null;

    var userProfileDataFromSessionStorageString = sessionStorage.getItem(Constants.SESSION_STORAGE_KEY_USER_PROFILE_DATA_WITH_TIMESTAMP);
    if (userProfileDataFromSessionStorageString) {
      var userProfileDataFromSessionStorage = JSON.parse(userProfileDataFromSessionStorageString);
      if ((new Date().getTime() - userProfileDataFromSessionStorage.timestamp) < 1000 * 60 * 60) { // 1 hour
        userProfileViewModels = userProfileDataFromSessionStorage.viewModels;
        isLoaded = true;
      } else {
        sessionStorage.removeItem(Constants.SESSION_STORAGE_KEY_USER_PROFILE_DATA_WITH_TIMESTAMP);
      }
    }

    this.state = {
      isLoaded: isLoaded,
      userProfileViewModels: userProfileViewModels,
      fromCache: isLoaded,
      region: props.region,
      viewerId: props.viewerId
    };

    console.log("this.state", JSON.stringify(this.state));
  }

  componentDidMount() {

    if (!this.state.isLoaded) {
      fetch(
        Constants.HS_SEARCH_API_URL
      )
      .then(response => response.json())
      .then(dataModels => {
        var viewModels = [];
        for (var i = 0; i < dataModels.length; i++) {
          viewModels.push(transformToViewModel(dataModels[i]));
        }
        this.setState({
          userProfileViewModels: viewModels,
          isLoaded: true,
        });
        var storageModel = {
          viewModels: viewModels,
          timestamp: new Date().getTime()
        };
        sessionStorage.setItem(Constants.SESSION_STORAGE_KEY_USER_PROFILE_DATA_WITH_TIMESTAMP, JSON.stringify(storageModel));
      })
      .catch(err => console.log(err));
    }
  }

  getGridListCols = () => {

    const { width } = this.props;

    if (isWidthUp('xl', width)) {
      return 4;
    }

    if (isWidthUp('lg', width)) {
      return 4;
    }

    if (isWidthUp('md', width)) {
      return 3;
    }

    if (isWidthUp('sm', width)) {
      return 3;
    }

    return 3;
  }

  getGridCellHeight = () => {

    const { width } = this.props;

    if (isWidthUp('xl', width)) {
      return '15vw';
    }

    if (isWidthUp('lg', width)) {
      return '15vw';
    }

    if (isWidthUp('md', width)) {
      return '26.66vw';
    }

    if (isWidthUp('sm', width)) {
      return '32.5vw';
    }

    return '32.5vw';
  }

  render() {

    const { classes } = this.props;

    if (!this.state.isLoaded) {
      return MiscFunctions.DISPLAY_SPINNER_IN_MIDDLE;
    } else {
        return (
          <React.Fragment>
            <div style={{paddingTop: '40px', paddingBottom: '20px'}}>
              <Typography variant="h4" gutterBottom marked="center" align="center">
                Search
              </Typography>
              <Typography variant="body2" align="center">
                {this.state.userProfileViewModels.length}
                {' hair stylists found' + (this.state.region ? " in " + this.state.region : "")}
              </Typography>
            </div>
            <br/>
            <div className={classes.root}>
              <List className={classes.list}>
                {this.state.userProfileViewModels.map((userProfileViewModel, index) => (
                  <React.Fragment key={userProfileViewModel.id}>
                    <ListItem alignItems="flex-start"
                      onClick={() => this.props.history.push(
                          '/search/' + userProfileViewModel.id,
                          {
                            profile: this.state.userProfileViewModels[index],
                            viewerId: this.state.viewerId
                          })}
                    > 
                    <ListItemAvatar>
                      <ProfilePicture profilePicture={userProfileViewModel.profilePicture}/>
                    </ListItemAvatar>
                    <ListItemText
                      primary={userProfileViewModel.firstName + " " + userProfileViewModel.lastName}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                              {this.combineUserInfo(userProfileViewModel)}
                          </Typography>
                          {this.combineUserInfo(userProfileViewModel) && <br/>}
                          {/*
                          <span style={{verticalAlign: 'top'}}>
                            {userProfileViewModel.stats.rating != null ?
                                <Rating value={userProfileViewModel.stats.rating} precision={0.1} readOnly size="small"/>
                              : ""
                            }
                            </span>
                            &nbsp;
                            <span style={{verticalAlign: 'top'}}>
                              {userProfileViewModel.stats.rating != null ?
                                userProfileViewModel.stats.rating
                                : "Rating N/A"
                              }
                            &nbsp;&nbsp;&nbsp;&nbsp;{userProfileViewModel.stats.reviewsCount} Reviews</span>
                            */}
                        </React.Fragment>
                      }
                    />
                    </ListItem>
                    <Divider variant="inset" component="li" style={{marginRight: '30px'}}/>
                  </React.Fragment>
                ))}
              </List>
            </div>
          </React.Fragment>
        )
    }
  }

 combineUserInfo = (userProfileViewModel) => {

    var combinedUserInfo = ""; //userProfileViewModel.occupation;
    if (userProfileViewModel.salon.name) {
      combinedUserInfo +=
        (
         // " • " +
          userProfileViewModel.salon.name + " • " +
          userProfileViewModel.salon.suburb + " • " +
          userProfileViewModel.salon.state
        )
    }

    return combinedUserInfo
  }
}

function ProfilePicture(props) {

  const { classes, profilePicture } = props;

  const noProfilePictureStyle = {
    backgroundColor: '#D3D3D3'
  };

  return (
    <>
      {
        profilePicture &&
        <Avatar src={profilePicture}/>
      }
      {
        !profilePicture &&
        <Avatar style={noProfilePictureStyle}>
          <PersonIcon htmlColor="white" style={{fontSize: '30px'}}/>
        </Avatar>
      }
    </>
  )
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(Search));
